import axios from "axios";
import * as rax from 'retry-axios';


const defaultBaseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000';
const defaultSocketURL = defaultBaseURL; // process.env.VUE_APP_SOCKET_URL || 'http://localhost:3100';
const isProductionBuild = process.env.VUE_APP_FED_ENV === 'production';
let url;

function createApi() {
  let baseURL;
  if (!isProductionBuild && localStorage.getItem('useProduction') === 'true') {
    if (!confirm('you are using a test build on live production data.  be aware that all data changes will impact real users.  click cancel to go back to the test api server')) {
      localStorage.removeItem('useProduction');
      location.reload();
    } else {
      baseURL = 'https://api.fedfedfed.com/';
    }
  } else {
    baseURL = defaultBaseURL;
  }

  const raxConfig = {
    // You can detect when a retry is happening, and figure out how many
    // retry attempts have been made
    onRetryAttempt: err => {
      const cfg = rax.getConfig(err);
      console.log(`Retry attempt #${cfg.currentRetryAttempt} of ${cfg.retry}.  rax.shouldRetryRequest(err)=${rax.shouldRetryRequest(err)}`);
    },
    // shouldRetry(err) {
    //   console.log('should retry?', rax.shouldRetryRequest(err))
    //   return rax.shouldRetryRequest(err);
    // },
    statusCodesToRetry: [[399, 401], [500, 599]],
    retry: 10,

  };
  url = baseURL;
  const api = axios.create({
    baseURL: baseURL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    },
    raxConfig
  });

  rax.attach(api);


  let lastWarning = 0;
  // eslint-disable-next-line no-constant-condition
  //if (false)
  api.interceptors.response.use(
    (config) => {
      return config;
    }, (error) => {
      if (!error.response) {
        console.log('api error, no response', error);
        alert('There seems to be a problem connecting to the server.  \n\nIf your internet connection is working and this message persists, please contact the admin.');
      } else {
        console.log('api error', error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          if (Date.now() - lastWarning > (30 * 1000)) {
            lastWarning = Date.now();
            alert("Server authorization required.  If you are signed in, it might be your role isn't allowed to do this operation.");
          }
          location.reload();
        }
      }
      if (api.errorHandler) {
        try {
          api.errorHandler(error);
        } catch (e) {
          console.error('failure in error handler', e);
        }
      } else {
        console.log('api error', error);
      }
      return Promise.reject(error);
    });


  api.setErrorHandler = (handler) => {
    api.errorHandler = handler;
  }
  api.showError = ({message}) => {
    api.errorHandler && api.errorHandler({message});
  }

  api.setToken = (token) => {
    api.defaults.headers.Authorization = 'bearer ' + token;
  }
  api.isProductionApi = baseURL === 'https://api.fedfedfed.com/';
  return api;
}

function useProductionApi(useProduction) {
  if (useProduction) {
    localStorage.setItem('useProduction', 'true');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    location.reload();
  } else {
    localStorage.removeItem('useProduction');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    location.reload();
  }
}

const axiosInstance = createApi(defaultBaseURL);
const socketUrl = defaultSocketURL;
export {url, socketUrl};
export default {
  isProductionBuild,
  useProductionApi,
  ...axiosInstance,
};
