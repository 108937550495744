<template>
  <!--  hide-no-data-->
  <!--  hide-selected-->
  <!--  return-object-->
  <!--  autofocus-->
  <!--      v-click-outside="isOpen && close()"-->
  <v-autocomplete
      clearable
      no-filter
      :label="label"
      @focus="isOpen=true"
      @blur="isOpen && close()"
      @change="isOpen && close()"
      @keyup.native.enter="isOpen && close()"
      v-model="editValue"
      :items="entries"
      :search-input.sync="search"
      color="black"
      dont-auto-select-first
      item-text="itemText"
      item-value="id"
      placeholder="Search for a meal"
      :suffix="`${editValue?editValue.id:''}`"
      :hint="editValue?`${editValue.name}, ${editValue.id}, ${getDateString(editValue.date)}`:''"
      return-object
      :hide-no-data="true"
  >
    <template v-slot:append-item>
      <div v-intersect="searchMore"/>
    </template>
  </v-autocomplete>
</template>

<script>
import api from '@/api';
import {deepCopy, getDateString} from '@/store/utils';
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    meal: Object,
    label: String,
    hideNonSkuStarredMeals: {type: Boolean, default: false, required: false}
  },
  created() {
    return this.fetchDiets();
  },
  mounted() {
    const item = this.editValue = deepCopy(this.meal);
    if (item) {
      item.itemText = this.mealToString(item);
    }
    //  console.log('mealAutocomplete mounted', this.editValue);
    this.entries = this.editValue ? [this.editValue] : [];
  },
  data() {
    return {
      editValue: null,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      count: 0,
      from: 0,
      isOpen: null
    };
  },
  watch: {
    editValue(meal) {
      if (meal && meal.name !== this.search) {
        this.search = meal.name;
      }
    },
    search(query) {
      this.performSearch(query);
    }
  },
  computed: {
    ...mapGetters(['getDietName'])
  },
  methods: {
    ...mapActions(['fetchDiets']),
    getDateString,
    close() {
      // console.log('closing', this.editValue);
      this.$emit('close', this.editValue);
      this.$emit('update:meal', this.editValue);
    },
    searchMore() {
      const nextFrom = this.entries.length;
      if (nextFrom < this.count) {
        this.performSearch(this.query, nextFrom);
      } else {
        //  console.log('already at the end', {count: this.count, loaded: this.entries.length});
      }
    },
    async performSearch(query, from = 0) {
      query = query ? query.trim() : '';
      if (!query) {
        //  console.log('no query')
        return;
      }
      //  console.log('query', query);
      if (this.isLoading) {
        //  console.log('Items have already been requested');
        return;
      }
      this.isLoading = true;

      const queryIsNumber = Number(query).toFixed(0) === query;


      const where = {};

      if (queryIsNumber) {
        where.id = Number(query);
      } else {
        const queryParts = query.split(' ').map(p => p.trim()).filter(p => !!p);
        if (queryParts.length === 1) {
          where.name = {
            contains: query,
            mode: 'insensitive',
          };
        } else {
          where.AND = queryParts.map(qp => ({
            name: {
              contains: qp,
              mode: 'insensitive',
            }
          }));
        }
        where.date = {
          not: null
        }
      }


      if (this.hideNonSkuStarredMeals) {
        where.is_selected = {
          isNot: null
        };
      }

      try {
        const params = {
          from,
          orderBy: [{date: 'desc'}],
          where,
          minimal: true
        };
        let result = await api.get(`/v2/meal`, {params}).then(res => res.data);

        if (result.count === 0) {
          // no result, so try searching non sku starred
          if (this.hideNonSkuStarredMeals) {
            delete where.is_selected;
            result = await api.get(`/v2/meal`, {params}).then(res => res.data);
          }
        }

        this.from = from;
        this.count = result.count
        result.meals.forEach(item => item.itemText = this.mealToString(item));
        if (from > 0) {
          //  console.log('appending')
          result.meals.forEach(e => this.entries.push(e));
        } else {
          //  console.log('replacing')
          this.entries = result.meals;
        }
        this.query = query;
        //  console.log('api search result', result);
        //  console.log('loaded entries', {entries: this.entries, from: this.from, count: this.count});
      } catch (err) {
        console.warn('search failed', err);
      }

      this.isLoading = false;
    }
    ,
    mealToString(item) {
      return !item ? '' : `${getDateString(item.date)} - ${item.sku} - ${this.getDietName(item.diet)} - ${item.name} (${item.id})`
    }
  }
}
</script>

<style scoped>

</style>
