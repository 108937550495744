<template>
  <v-app id="app" v-if="!isLoading">
    <v-container fluid class="d-print-none">

      <v-app-bar
        fixed
        class="d-print-none"
        :color="isProductionBuild?'':(!isProductionApi?'yellow':'pink')"
        v-if="(user && !user.none) && !hideTopNav"
        hide-on-scroll
      >
        <v-menu offset-y v-if="user">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              text
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list style="width:auto">
            <v-list-item-group>
              <v-list-item style="min-height: 0">
                <v-row no-gutters>
                  <v-col v-for="(linkChunk,index) of linkChunks" v-bind:key="index">
                    <v-list dense>
                      <v-list-item :style="links.length>10?'min-height: 0':''"
                                   v-for="link of linkChunk" v-bind:key="link.name"
                      >
                        <router-link :to="link">{{ toWords(link.name) }}</router-link>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list dense>
            <v-list-item-group>
              <v-list-item style="min-height: 0">
                <v-list-item-title>{{ user.email }}</v-list-item-title>
              </v-list-item>
              <v-list-item style="min-height: 0">
                <v-list-item-title>Role: {{ user.roleName }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.streams">
                <v-list-item-title>Streams: {{ user.streams }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="!isProductionBuild">
                <v-list-item-title>{{ api.defaults.baseURL }}</v-list-item-title>
                <v-list-item-action>
                  <v-switch
                    v-model="isProductionApi"
                    :label="(isProductionApi?'live':'test') + ' mode'"
                    @change="toggleApi"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn @click="confirmLogout">logout</v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-spacer/>
        <v-breadcrumbs>
          <v-breadcrumbs-item v-for="page of history" v-bind:key="page.name">
            <v-btn outlined text :to="page">{{ toWords(page.name) }}</v-btn>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-spacer/>
        <div class="mt-4" v-if="hasDates">
          <v-dialog v-model="showNextProduction" max-width="600">
            <v-card>
              <v-card-title>Next Production</v-card-title>
              <v-card-text>
                You are looking at the next production of meals for the following days
                <ul>
                  <li v-for="d of formattedNextProductionDays" v-bind:key="d">
                    {{ d }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showNotNextProduction" max-width="600">
            <v-card>
              <v-card-title>Other Production Days</v-card-title>
              <v-card-text>
                This is a warning to let you know you may not be looking at the right production dates.
                Based on it being {{ today }}, the next meal production dates are:
                <ul>
                  <li v-for="d of formattedNextProductionDays" v-bind:key="d">
                    {{ d }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-alert @click="showNextProduction=true" dense v-if="isNextProduction" color="success">Next Production
          </v-alert>
          <v-alert @click="showNotNextProduction=true" dense v-if="!isNextProduction" color="warning">Other Production
            Dates
          </v-alert>
        </div>
        <router-view name="toolbar"/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :class="isStale ? 'refresh-stale':''"
              v-bind="attrs"
              v-on="on"
              icon
              :loading="isReloading"
              @click="doReload()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <div>click to refresh data from server to ensure you are using the most recent amounts</div>
          <div v-if="isStale">data has not been updated in {{ minutesUntilStale }} minutes</div>

        </v-tooltip>
      </v-app-bar>

      <v-spacer class="d-print-none pb-14" v-if="!hideTopNav"/>


    </v-container>
    <!-- keep alive means components don't re-render
         (so a search on a datatable will stay at the same place when you navigate back
         -->

    <keep-alive include="MealDesign,MealDesignAdmin,MealScheduleTemplate">
      <router-view :key="$route.name"/>
    </keep-alive>

    <v-snackbar
      v-model="showErrors"
      color="warning"
      top
      dark
      :timeout="errorMessageTimeout"
    >
      Something unexpected happened. Please refresh the page and check your work.
      <br/>
      <ul>
        <li v-for="error of errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
      <!--      <template v-slot:action="{ attrs }">-->
      <!--        <v-btn-->
      <!--            icon-->
      <!--            v-bind="attrs"-->
      <!--            @click="showErrors = false"-->
      <!--        >-->
      <!--          <v-icon>mdi-close</v-icon>-->
      <!--        </v-btn>-->
      <!--      </template>-->
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

#nav {
  //padding: 30px;
  //height: 30px;

  //a {
  //  font-weight: bold;
  //  color: #2c3e50;
  //
  //  &.router-link-exact-active {
  //    color: #42b983;
  //  }
  //}
}

.refresh-stale {
  background: tomato;
}
</style>
<script>
import {mapActions, mapGetters} from 'vuex';
import api from './api';
import moment from 'moment';
import * as Sentry from "@sentry/vue";
import {getNextProductionDays, getProductionDays} from "@/store/utils";

const {mapState} = require('vuex');
let errorTimeout;

export default {
  async created() {
    setTimeout(() => this.isStale = true, this.minutesUntilStale * 60 * 1000);
    // check this on a schedule in case someone leaves the window open
    setInterval(() => this.checkProductionState(), this.minutesUntilStale * 60 * 1000);
    for (let i = 0; i < 7; i++) {
      const d = moment('2024-07-07').add(i, 'days');
      const nextProductionDates = getNextProductionDays(d).map(d => `${moment(d).format('ddd')} (${d})`);
      console.log(`looking at k.fed on ${d.format('ddd')} (${d.format(moment.HTML5_FMT.DATE)}) you should see ${nextProductionDates.join(', ')}`);
    }
    this.checkProductionState();
    const date = (this.$route.params.date && moment(this.$route.params.date)) || false;
    if (date) {
      this.setDate(date.format(moment.HTML5_FMT.DATE));
    }
    const user = await this.fetchCurrentUser();
    try {
      Sentry.setUser(user);
    } catch (e2) {
      console.warn('sentry setUser failed', e2);
    }

    this.isLoading = false;
    const self = this;
    api.setErrorHandler((e) => {

      console.log('error handler called: ', e);
      let message = (e && e.response && e.response.data && e.response.data.error) || e.message;
      if (typeof message !== 'string') {
        message = JSON.stringify(message);
      }
      if (e.config) {
        const {url, method} = e.config;
        message = `${method} ${url} - ${message}`;
      }
      if (!self.errors.includes(message)) {
        self.errors.push(message);
      }
      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }
      errorTimeout = setTimeout(() => {
        errorTimeout = undefined;
        self.errors = [];
      }, self.errorMessageTimeout);

      try {
        Sentry.captureException(e);
      } catch (e2) {
        console.warn('sentry failed to capture', e);
        console.warn('sentry error', e2);
      }
    });
  },
  watch: {
    dates: 'checkProductionState',
    date: 'checkProductionState',
    errors() {
      this.showErrors = this.errors.length > 0;
    },
    isProduction(value) {
      if (value) {
        api.useProductionApi(value);
      }
    },
    $route(val) {
      // console.log('adding', val);
      const index = this.history.findIndex(page => page.name === val.name);
      if (index < 0) {
        this.history.push(val);
      } else {
        this.history[index] = val;
      }
      if (this.history > 5) {
        this.history.shift();
      }
    }
  },
  data() {
    return {
      isLoading: true,
      isReloading: false,
      kitchen_v1_url: process.env.V1_URL || 'https://fed-kitchen.netlify.app/',
      isProductionApi: api.isProductionApi,
      isProductionBuild: api.isProductionBuild,
      isStale: false,
      history: [],
      errors: [],
      showErrors: false,
      errorMessageTimeout: 5000,
      minutesUntilStale: 15,
      isNextProduction: null,
      showNextProduction: null,
      showNotNextProduction: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'hideTopNav'
    ]),
    ...mapGetters([
      'date'
    ]),
    api() {
      return api;
    },
    formattedNextProductionDays() {
      return getNextProductionDays()
        .map(d => {
          d = moment(d);
          return `${d.format('dddd')} (${d.format('LL')})`;
        });
    },
    today() {
      const d = moment();
      return `${d.format('dddd')} (${d.format('LL')})`
    },
    dates() {
      return this.$route.query.dates;
    },
    links() {
      const dates = ((Array.isArray(this.dates) ? this.dates : [this.dates]) || []).filter(d => !!d).sort();
      const date = this.date || dates[0];
      return [
        {name: 'Meals', params: {date}},
        {name: 'MealList'},
        {name: 'MealDesign'},
        {name: 'MealDesignAdmin'},
        {name: 'MealSchedule'},
        {name: 'MealScheduleTemplate'},
        {name: 'MealImages'},
        {name: 'MealImageList'},
        {name: 'MealVersions'},
        {name: 'MealFilterList'},
        {name: 'Shopping'}, // query: {dates}},
        {name: 'Chopping'}, //, query: {dates}},
        {name: 'Precut', query: {dates}},
        {name: 'ChoppingTasks'},
        {name: 'Components'},
        {name: 'ComponentPrep', query: {dates}},
        {name: 'ComponentPrep2', query: {dates}},
        {name: 'Component Demand', query: {dates}},
        {name: 'ComponentTasks', query: {dates}},
        {name: 'ComponentWeighTasks', query: {dates}},
        {name: 'Ingredients'},
        {name: 'IngredientNutrients'},
        {name: 'Orders', query: {dates}},
        {name: 'OrderEdit', query: {dates}},
        {name: 'Packing', query: {dates}},
        {name: 'Delivery', query: {dates}},
        {name: 'Delivery2', query: {dates}},
        {name: 'Labels', query: {dates}},
        {name: 'PlatingTV', params: {date}},
        {name: 'PlatingTasks', query: {dates}},
        {name: 'PlatingTasks2', query: {dates}},
        {name: 'AllergyPlatingTasks', query: {dates}},
        {name: 'LabelsBasic'},
        {name: 'Metrics', params: {dates}},
        {name: 'Customers'},
        {name: 'CustomersFinance'},
        {name: 'Feedback', query: {dates: [this.startOfWeek]}},
        {name: 'Menu'},
        {name: 'Users'},
        {name: 'Roles'},
        {name: 'TaskReport'},
        {name: 'Plans'},
        {name: 'Drivers'},
        {name: 'Invoices'},
        {name: 'MealDesigner'},
        {name: 'MenuBuilder'},
        {name: 'Streams'},
        {name: 'CustomerCancelReasons'},
        {name: 'PackingTasks', query: {dates}},
        {name: 'MealAudit', query: {dates}},
        {name: 'MealScan'},
        {name: 'ComponentComparison'},
        {name: 'CorporateCodes'},
        {name: 'ShippingRules'},
        {name: 'Platings', query: {dates}},
        {name: 'Restrictions'},
        {name: 'ComponentAudit', query: {dates}},
        {name: 'ComponentWaste', query: {dates}},
        {name: 'AuditedShorts', query: {dates}},
        {name: 'ComponentShortTasks', query: {dates}},
        {name: 'MealIngredientModifier'}
      ]
        .filter(link => this.pages.includes(link.name))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    pages() {
      if (this.user.roleDetail) {
        //console.log('calc pages', this.user);
        const {readPages, writePages} = this.user.roleDetail;
        const pages = new Set([...readPages, ...writePages]);
        if (pages.size > 0) {
          return [...pages];
        } else {
          return this.$router.getRoutes().map(r => r.name);
        }
      } else {
        return [];
      }
    },
    pageTitle() {
      return this.$route.meta.title;
    },
    startOfWeek() {
      return this.dateText(moment(this.date).startOf('week'));
    },
    linkChunks() {
      const links = [...this.links];
      const chunks = [];
      const chunkSize = 20;
      while (links.length > 0) {
        chunks.push(links.splice(0, chunkSize));
      }
      return chunks;
    },
    hasDates() {
      return (this.dates && this.dates.length > 0);
    }
  },
  methods: {
    getProductionDays,
    ...mapActions([
      'reload',
      'fetchCurrentUser',
      'logout',
      'setDate'
    ]),
    dateText(d) {
      return moment(d).format('YYYY-MM-DD');
    },
    async doReload() {
      this.isReloading = true;
      const minimumTimePromise = new Promise((resolve) => {
        // may seem weird, but if it is too fast, people may want to mash the button
        // so we make sure it looks like it is loading for at least 1 sec
        setTimeout(() => resolve(), 1000);
      });
      // await this
      //   .reload()
      //   .catch(e => {
      //     console.warn('reload had some errors', e);
      //   });
      window.location.reload();
      await minimumTimePromise;
      this.isReloading = false;
    },
    confirmLogout() {
      if (confirm('Do you want to log out?')) {
        this.logout();
      }
    },
    toggleApi() {
      this.$nextTick(() => {
        if (confirm(`would you like to switch to the ${this.isProductionApi ? 'production' : 'staging'} api?`)) {
          api.useProductionApi(this.isProductionApi);
        } else {
          // revert boolean back to original value
          this.isProductionApi = !this.isProductionApi;
        }
      });
    },

    /**
     * converts 'StudlyCaps' into 'Studly Caps'
     * @param s
     * @returns {*}
     */
    toWords(s) {
      return s.replace(/([A-Z])/g, (match, p1) => ` ${p1}`).trim();
    },
    checkProductionState() {
      const nextProductionDays = getNextProductionDays(moment());
      // this is for checking order edit
      if (this.dates && this.dates.length === 2) {
        let [dateStart, dateEnd] = this.dates.slice().sort();

        // if the date range is a week - do this other logic - used on order-edit and meal audit pages
        if (moment(dateStart).day() === 0 && moment(dateEnd).diff(dateStart, 'days') === 6) {
          const dayStart = moment(dateStart);
          if (dayStart.day() === 0) {
            dateStart = dayStart.add(1, 'days').format(moment.HTML5_FMT.DATE);
          }
          dateStart = moment(dateStart).format(moment.HTML5_FMT.DATE)
          console.log('checking ', dateStart, dateEnd);
          this.isNextProduction = nextProductionDays.includes(dateStart) || nextProductionDays.includes(dateEnd);
          // break out
          return;
        }
      }

      // fallback/default
      const date = (this.dates && this.dates[0]) || this.date;
      this.isNextProduction = nextProductionDays.includes(date);
    },

  }
}
</script>
