import * as Sentry from "@sentry/vue";

export function checkVersion(options = {}) {
  const defaults = {
    checkInterval: 60000
  }
  options = {...defaults, ...options};
  const {
    srcRoot,
    checkInterval,
    debug = false,
    hostname,
    method = 'GET'
  } = options;

  if (!srcRoot) {
    console.warn('no src root');
    return;
  }

  if (hostname && window.location.hostname !== hostname) {
    console.log('check version not enabled for this host');
    return;
  }

  function checkExists(appSource, checkInterval) {
    const check = () => {
      fetch(appSource, {method})
        .then(r => {
          if (r.status === 404) {
            Sentry.captureMessage('old client upgrade required', {
              contexts: {'current-version': {appSource}}
            });
            alert('A new version of our website is available.');
            window.location.reload();
          } else {
            if (debug) {
              console.log('current version of website is still', appSource);
            }
            // queue next check
            checkExists(appSource, checkInterval);
          }
        })
        .catch(e => {
          Sentry.captureException(e);
        })
    };
    setTimeout(check, checkInterval);
  }

  try {
    const scriptTags = document.getElementsByTagName('script');
    const appSource = [...scriptTags]
      .map(s => s.src)
      .filter(src => src.startsWith(srcRoot))[0];

    if (!appSource) {
      throw new Error('could not determine app source.  no version check available');
    } else {
      if (debug) {
        console.log('current version of website', appSource);
      }
    }
    checkExists(appSource, checkInterval);
  } catch (e) {
    console.error('check version setup failed', e);
    Sentry.setContext('error', {error: e});
    Sentry.captureException(new Error('failed to execute check version: ' + e.message));
  }
}

