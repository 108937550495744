import {convertIngredient, getStartOfWeek} from '@/store/utils';
import api from '@/api';
import {setUserToken} from '@/socket';
import moment from "moment";
import diff from "microdiff";

const localforage = require('localforage');

/**
 * if function has not been executed, create a new promise.
 * wait for the promise to resolve.  ensures api calls are
 * made exactly once.
 *
 * @param loaded
 * @param id
 * @param fn
 * @returns {Promise<void>}
 */

const fetchFunctionMap = {};

async function loadOrWait(loaded, id, fn, force) {
  if (!loaded[id] || force) {
    loaded[id] = fn();
  }
  fetchFunctionMap[id] = fn;
  await loaded[id];
}

function reload(loaded) {
  if (fetchFunctionMap) {
    return Promise.all(
      Object.keys(fetchFunctionMap)
        .map(id => {
          // console.log('state fetch reload '+id);
          loaded[id] = fetchFunctionMap[id]();
          return loaded[id];
        })
    );
  } else {
    console.log('no state fetch to reload');
    return Promise.resolve([]);
  }
}

export default {
  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    // reload it all, wipe state
    window.location.reload();
  },
  async fetchCurrentUser({commit}) {
    const token = localStorage.getItem('token');
    let user = token &&
      await api.get('users')
        .then(response => response.data)
        .catch((e) => {
          console.warn('failed to get current user', e);
          return {none: true};
        });
    if (user) {
      // full story identify user
      try {
        if (window.FS && window.FS.identify) {
          window.FS.identify(user.id, {
              displayName: `${user.first_name} ${user.last_name}`,
              email: user.email
            }
          );
        }
      } catch (e) {
        console.error('FS failure', e);
      }
      try {
        setUserToken(token);
      } catch (e) {
        console.error('failed to set user token', e);
      }
    } else {
      user = {none: true};
    }
    commit('SET_CURRENT_USER', {
      user,
      token
    });
    return user;
  },
  reload({state}) {
    // console.log('reloading state', state.loaded);
    return reload(state.loaded);
  },
  setDate({commit}, date) {
    commit('SET_DATE', date);
  },
  async fetchOrders({
                      commit,
                      state
                    }, date) {
    if (!date) {
      console.warn('fetchOrders - no date passed no orders to fetch')
      return [];
    } else {
      date = getStartOfWeek(date);
      const key = date;
      await loadOrWait(state.loaded.orders, key, async () => {
        const response = await api.get(`orders/${date}?fields=meals,stream,meal_size,allergies,uid`);
        // console.log('fetchOrders response.data', response.data);
        commit('SET_ORDERS', {
          orders: response.data,
          key
        });
      });
      return state.orders[key];
    }
  },
  async fetchCustomers({
                         commit,
                         state
                       }) {
    await loadOrWait(state.loaded, 'customers', async () => {
      let cachedCustomers;
      try {
        cachedCustomers = await localforage.getItem('customers');
      } catch (e) {
        console.warn('failed to parse cached customers');
        cachedCustomers = false;
      }

      if (cachedCustomers) {
        console.log('using cached customers');
        commit('SET_CUSTOMERS', cachedCustomers);
        api.get(`users/firebase`).then(response => {
          const customers = response.data;

          function hasDiff() {
            try {
              console.log('hasdiff customers', customers);
              console.log('hasdiff cachedCustomers', cachedCustomers);
              return diff(customers, cachedCustomers).length > 0;
            } catch (e) {
              console.warn('diff failed', e);
              return true;
            }
          }

          if (!cachedCustomers || hasDiff()) {
            console.log('cached customers was out of date, updating')
            localforage.setItem('customers', customers);
            commit('SET_CUSTOMERS', customers);
          } else {
            console.log('cached customers was up to date')
          }
        })
      } else {
        const response = await api.get(`users/firebase`);
        // console.log('fetchCustomers response.data', response.data);
        commit('SET_CUSTOMERS', response.data);
        localforage.setItem('customers', response.data);
      }
    });
    return state.customers;
  },

  async fetchFeedback({
                        commit,
                        state
                      }, {
                        dateFrom,
                        dateTo
                      }) {
    console.log('fetch feedback', {dateFrom, dateTo});
    await loadOrWait(state.loaded, 'feedback', async () => {
      const response = await api.get(`feedback`);
      // console.log('fetchFeedback response.data', response.data);
      commit('SET_FEEDBACK', response.data);
    });
    return state.feedback;
  },
  async fetchOrdersForMeal({
                             commit,
                             state
                           }, {
                             date,
                             mealId
                           }) {
    if (!date) {
      console.warn('fetchOrdersForMeal: date was not set');
      return;
    } else {
      const key = `${date}_${mealId}`;
      await loadOrWait(state.loaded.orders, key, async () => {
        const url = `v2/order/${date}?meal_id=${mealId}`;
        // console.log('fetchOrdersForMeal get', url);
        const response = await api.get(url);
        // console.log('fetchOrdersForMeal response.data', response.data);
        commit('SET_ORDERS', {
          orders: response.data,
          key: false
        });
        return response.data;
      });
    }
  },
  async fetchOrdersByDate({
                            commit,
                            state
                          }, {
                            date,
                            uid,
                            refetch = false
                          }) {
    if (!date) {
      console.warn('fetchOrdersByDate: date was not set');
      return;
    }
    const key = `orders_by_date_${date}` + (uid ? `_${uid}` : '');
    if (refetch) {
      state.loaded.orders[key] = false;
    }
    await loadOrWait(state.loaded.orders, key, async () => {
      console.log('fetchOrdersByDate', {
        key,
        refetch
      });
      const url = `v2/order/${date}`;
      // console.log('fetchOrdersByDate get', url);
      const params = uid ? {uid} : undefined;
      const response = await api.get(url, {params});
      // console.log('fetchOrdersByDate response.data', response.data);
      commit('SET_ORDERS', {
        orders: response.data,
        key
      });
      return response.data;
    });
    return state.orders[key];
  },
  async fetchMeals({
                     commit,
                     state
                   }, date) {
    if (!date) {
      console.error('fetching meals but date not set', new Error('no date'));
    }
    await loadOrWait(state.loaded.meals, date, async () => {
      const response = await api.get(`meals/${date}`);
      // console.log('fetchMeals response.data', response.data);
      state.meals[date] = response.data;
      commit('SET_MEALS', {
        meals: response.data,
        date
      })
    });
    return state.meals[date];
  },
  async fetchExtras({
                      commit,
                      state
                    }) {
    const key = 'extras';
    await loadOrWait(state.loaded.meals, key, async () => {
      const response = await api.get(`meals/extras`);
      state.meals[key] = response.data;
      commit('SET_MEALS', {
        meals: response.data,
        date: key
      })
    });
    return state.meals[key];
  },
  async fetchSkus({
                    commit,
                    state
                  }) {
    await loadOrWait(state.loaded, 'skus', async () => {
      const response = await api.get(`v2/meal/sku`);
      console.log('fetchSkus response.data', response.data);

      commit('SET_SKUS', response.data);
    })
    return state.diets;
  },
  async fetchComponentBatches({
                                commit,
                                state
                              }, {
                                mealId,
                                productionKey
                              }) {
    const key = `${mealId}-${productionKey}`;
    await loadOrWait(state.loaded.componentBatches, key, async () => {
      const {data} = await api.get(`v2/meal/batch/${mealId}/${productionKey}`);
      commit('SET_COMPONENT_BATCHES', {
        key,
        value: data
      });
      return data;
    });
    // console.log('state.loaded.componentBatches[key]',key, state.loaded.componentBatches[key]);
    return state.loaded.componentBatches[key];
  },
  async fetchDiets({
                     commit,
                     state
                   }) {
    await loadOrWait(state.loaded, 'diets', async () => {
      const response = await api.get(`diets/`);
      // console.log('fetchDiets response.data', response.data);
      const diets = response.data.reduce((acc, i) => {
        acc[i.id] = i;
        return acc;
      }, {});
      commit('SET_DIETS', {diets});
    })
    return state.diets;
  },
  async fetchNutrients({
                         commit,
                         state
                       }) {
    await loadOrWait(state.loaded, 'nutrients', async () => {
      const response = await api.get(`nutrients/`);
      // console.log('fetchNutrients response.data', response.data);
      const nutrients = response.data.reduce((acc, i) => {
        acc[i.nutrientnameid] = i;
        return acc;
      }, {});
      commit('SET_NUTRIENTS', {nutrients});
    })
    return state.nutrients;
  },
  async fetchStreams({
                       commit,
                       state
                     }) {
    await loadOrWait(state.loaded, 'streams', async () => {
      const response = await api.get(`meals/streams`);
      // console.log('fetchStreams response.data', response.data);
      const streams = response.data
        .filter(s => !!s)
        .reduce((acc, i) => {
          acc[i.id] = i;
          return acc;
        }, {});
      commit('SET_STREAMS', {streams});
    })
    return state.streams;
  },
  async fetchPlans({
                     commit,
                     state
                   }, {refetch = false} = {}) {
    if (refetch) {
      state.loaded.plans = false;
    }
    await loadOrWait(state.loaded, 'plans', async () => {
      const response = await api.get(`plans`);
      // console.log('fetchPlans response.data', response.data);
      commit('SET_PLANS', {plans: response.data});
    })
    return state.plans;
  },
  async fetchShipping({
                        commit,
                        state
                      }) {
    await loadOrWait(state.loaded, 'shipping', async () => {
      const response = await api.get(`v2/shipping`);
      // console.log('fetchShipping response.data', response.data);
      commit('SET_SHIPPING', {shipping: response.data});
    })
    return state.shipping;
  },
  async fetchExportStatus({
                            commit,
                            state
                          }, id) {
    const fetch = async () => {
      // console.log('fetchExportStatus',id);
      const response = await api.get(`meals/export/${id}`);
      // console.log('fetchExportStatus',{id, status: response.data});
      commit('SET_EXPORT_STATUS', {
        id,
        status: response.data
      });
    };
    // await loadOrWait(state.loaded.exportStatus, id, fetch);
    await fetch();
    return state.exportStatus[id];
  },
  async fetchRestrictions({
                            commit,
                            state
                          }) {
    await loadOrWait(state.loaded, 'restrictions', async () => {
      const response = await api.get(`substitutions/`);
      // console.log('fetchDietRestrictions response.data', response.data);
      const restrictions = Object
        .values(response.data)
        .sort((a, b) => (a.text || '').localeCompare(b.text || ''))
        .reduce((acc, i) => {
          acc[i.id] = i;
          if (i.restrictedTags) {
            i.restrictedTags.forEach(tagId => {
              if (i.id === 'milk') {
                // this wastes so much space !
                i.text = i.text.replace(' (unavailable for keto)', '');
              }
              acc[tagId] = acc[tagId] || {};
              acc[tagId][i.id] = i;
            })
          }
          return acc;
        }, {});
      // console.log('SET_RESTRICTIONS', {restrictions});
      commit('SET_RESTRICTIONS', {restrictions});
    })
    return state.restrictions;
  },
  async fetchFoodTags({
                        commit,
                        state
                      }) {
    await loadOrWait(state.loaded, 'food_tags',
      async () => {
        const response = await api.get(`tags`);
        // console.log('fetchComponent response.data', response.data);
        const tags = response.data;
        // console.log('fetchComponent map', component);
        commit('SET_FOOD_TAGS', {tags});
      });
    return state.food_tags;
  },
  async fetchFedInvoice({
                          commit,
                          state
                        }, {
                          date,
                          uid,
                          refetch
                        }) {
    date = moment(date).startOf('week').format(moment.HTML5_FMT.DATE);
    const key = `${date}/${uid}`;
    if (refetch) {
      state.loaded.invoices[key] = false;
    }
    await loadOrWait(state.loaded.invoices, key, async () => {
      const response = await api.get(`v2/order/invoice/${date}/${uid}`);
      console.log('fetchinvoice response.data', response.data);
      commit('SET_INVOICE', {
        key,
        invoice: response.data
      });
    })
    return state.invoices[key];
  },
  async fetchMenu({
                    commit,
                    state
                  }, date) {
    date = moment(date).startOf('week').format(moment.HTML5_FMT.DATE);
    await loadOrWait(state.loaded.menus, date, async () => {
      const response = await api.get(`v2/menu/${date}`);
      console.log('fetchmenu response.data', response.data);
      commit('SET_MENU', {
        date,
        menu: response.data
      });
    })
    return state.menus[date];
  },
  async fetchPersonalMenu({
                            commit,
                            state
                          }, {
                            date,
                            uid
                          }) {
    date = moment(date).startOf('week').format(moment.HTML5_FMT.DATE);
    const key = `${date}/${uid}`;
    await loadOrWait(state.loaded.menus, key, async () => {
      const response = await api.get(`v2/public/menu-generate/personal/${date}/${uid}`, {params: {full: true}});
      console.log('fetchmenu response.data', response.data);
      commit('SET_MENU', {
        date: key,
        menu: response.data
      });
    })
    return state.menus[key];
  },
  async fetchComponent({
                         commit,
                         state
                       }, id) {
    let options = {force: false};
    if (typeof id === "object") {
      options = id;
      id = options.id;
    }
    if (options.force) {
      state.loaded.components[id] = false;
    }
    await loadOrWait(state.loaded.components, id,
      async () => {
        const response = await api.get(`v2/component/${id}`);
        // console.log('fetchComponent response.data', response.data);
        const component = response.data;
        // console.log('fetchComponent map', component);
        commit('SET_COMPONENT', {
          id,
          component
        });
      });
    return state.components[id];
  },
  async fetchComponentTags({
                             commit,
                             state
                           }) {
    await loadOrWait(state.loaded, 'component_tags',
      async () => {
        const response = await api.get(`v2/component/tags`);
        // console.log('fetchComponent response.data', response.data);
        const componentTags = response.data;
        // console.log('fetchComponent map', component);
        commit('SET_COMPONENT_TAGS', {componentTags});
      });
    return state.componentTags;
  },
  async fetchMealNameAndDiet({
                               commit,
                               state
                             }, id) {
    await loadOrWait(state.loaded.mealNameAndDiet, id, async () => {
      const url = `v2/public/meal/${id}`;
      const response2 = await api.get(url);
      const meal = response2.data;
      commit('SET_MEAL_NAME_AND_DIET', {
        id,
        meal
      });
    })
    return state.meal[id];
  },
  async fetchContainers({
                          commit,
                          state
                        }, options = {force: false}) {
    if (options.force) {
      state.loaded.containers = false;
    }
    await loadOrWait(state.loaded, 'containers', async () => {
      api.get('v2/container')
        .then(r => {
          const containers = r.data.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
          commit('SET_CONTAINERS', {containers});
          return containers;
        });
    });
    return state.containers;
  },
  async fetchMeal({
                    commit,
                    state
                  }, id) {
    let options = {force: false};
    if (typeof id === "object") {
      options = id;
      id = options.id;
    }
    if (options.force) {
      state.loaded.meal[id] = false;
    }
    await loadOrWait(state.loaded.meal, id, async () => {
      const url = `v2/meal/${id}`;
      const response2 = await api.get(url);
      const meal = response2.data;
      meal.id = meal.id || id;
      // console.log('set meal',id);
      commit('SET_MEAL', {
        id,
        meal
      });
      return meal;
    })
    return state.meal[id];
  },
  async fetchMealsById({
                         commit,
                         state
                       }, ids) {
    const idsBefore = ids;
    // console.log('before filter ids', ids);
    const idsToFetch = ids.filter(id => !state.loaded.meal[id]).map(id => id.replace(/CC$/, ''));
    // console.log('filtered ids', idsToFetch);
    await loadOrWait(state.loaded.meal, idsToFetch.join(','), async () => {
      const response = await api.post(`meals/by-id`, {
        ids: idsToFetch
      });
      if (response.data.length !== idsToFetch.length) {
        console.warn('did not correct number of results', response.data);
      }

      commit('SET_MEALS', {meals: response.data});

    })
    return idsBefore.map(id => state.meal[id]);
  },

  async fetchSubMeals({
                        commit,
                        state
                      }, {
                        id,
                        force = false
                      }) {
    await loadOrWait(state.loaded.submeals, id, async () => {
      const response = await api.get(`meals/version?main=${id}&parent=1`);
      // console.log('submeals for ' + id, response.data);
      response.data.forEach(m => m.parentMealId = id);
      commit('SET_SUB_MEALS', {
        id,
        meals: response.data
      });
    }, force)
    return state.meal[id];
  },
  async fetchMealIngredients({
                               commit,
                               state,
                               dispatch,
                               getters
                             }, {
                               id,
                               force = false
                             }) {
    if (force) {
      state.loaded.ingredients[id] = false;
    }
    await loadOrWait(state.loaded.ingredients, id, async () => {
      const response = await api.get(`meals/details/${id}?skip-detail=true`);
      const ingredients = response.data;
      // console.log(`meals/details/${id}`, ingredients);
      // FIXME: component is now embedded in detail, do not need to fetch
      const promises = [];
      for (const ingredient of ingredients) {
        if (ingredient.component_id !== null) {
          promises.push(dispatch('fetchComponent', ingredient.component_id))
        }
      }
      await Promise.all(promises);

      // FIXME: component is already embedded in detail, do not need to fetch/set
      // inject components - yes, even ones with null component_id (they get the default component
      // which is used to set the small/large sizes)
      ingredients.forEach(i => {
        if (!i.component) {
          i.component = getters.getComponent(i.component_id);
        }

        if (i.component_id !== null && !i.component) {
          console.warn(`component ${i.component_id} was not loaded, so using default component!`, i);
          i.component = getters.getComponent(null);
        }
      });

      commit('SET_INGREDIENTS', {
        id,
        ingredients: ingredients.map(convertIngredient)
      });
      return ingredients;
    })
    return state.ingredients[id];
  }
}
