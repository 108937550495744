<template>
  <v-card flat>
    <v-snackbar
      top
      outlined
      v-model="showSnack"
      color="success"
      timeout="1000"
      centered
    >
      {{ snackMessage }}
    </v-snackbar>
    <v-toolbar flat>
      <MealAutocomplete
        :meal.sync="meal"
        v-bind:key="meal && meal.id"
      />
      <v-spacer/>
      <v-text-field
        label="Lookup by ID"
        v-model="lookupById"
        @keyup.enter="loadMeal(lookupById)"
      />

    </v-toolbar>

    <v-alert v-if="this.mealId && !this.meal">Could not find meal {{ this.mealId }}</v-alert>

    <v-form @submit.prevent="submitForm">
      <v-card-title v-if="meal">
        Images for Meal {{ meal.name }}
        (
        <router-link :to="{ name: 'MealDetail', params: { date: formatDate(meal.date), id: meal.id }}">
          M{{ meal.id ? `${meal.id}` : '' }}
        </router-link>
        )
        (
        <MealSkuLink :sku="meal.sku"/>
        )
        <v-spacer/>
        <v-btn type="submit" :loading="saving">Save</v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-model="packaged"
              prepend-icon="mdi-image"
              label="Choose packaged image..."
              accept="image/*"
            />
            <v-img v-if="packagedUrl"
                   :src="packagedUrl"
                   max-width="200"
                   max-height="200"
            />
            <span class="caption">{{ packagedUrl }}</span>
          </v-col>
          <v-col>
            <v-file-input
              v-model="plated"
              prepend-icon="mdi-image"
              label="Choose plated image..."
              accept="image/*"
            />

            <v-img v-if="platedUrl"
                   :src="platedUrl"
                   max-width="200"
                   max-height="200"
            />
            <span class="caption">{{ platedUrl }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="otherImages"
              prepend-icon="mdi-camera-burst"
              label="More Photos"
              multiple
              accept="image/*"
            />
          </v-col>
          <v-col/>

        </v-row>
        <v-row
          v-for="(other, index) of otherUrls"
          v-bind:key="index"
        >
          <v-col>
            {{ other.file.name }}
          </v-col>
          <v-col>
            <v-img
              :src="other.url"
              max-width="200"
              max-height="200"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn type="submit" :loading="saving">Save</v-btn>
      </v-card-actions>
      <v-card-text>
        <v-row>
          <v-col cols="4"
                 v-for="(image,index) of imagesSorted" v-bind:key="index"
          >
            <v-btn v-for="(slotName,slotId) of imageSlots"
                   v-bind:key="slotId"
                   text class="mx-2"
                   @click="setSlotUrl(slotId,image.url)"
                   :input-value="image.slot===slotId">
              {{ slotName }}
            </v-btn>
            <v-btn class="mx-2" icon
                   @click="setPhotoMealId(image.id,null)">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-img :src="image.url"
                   max-width="200"
                   max-height="200"
            />
            <span class="caption">{{ image }}</span>
          </v-col>
        </v-row>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import MealAutocomplete from '@/components/MealAutocomplete';
import api from '@/api';
import moment from 'moment';
import MealSkuLink from "@/components/MealSkuLink";
import {mapState} from "vuex";

export default {
  name: "Images",
  components: {MealSkuLink, MealAutocomplete},
  data() {
    return {
      meal: null,
      packaged: null,
      plated: null,
      otherImages: [],
      images: {},
      lookupById: null,
      showSnack: null,
      snackMessage: null,
      saving: false,
    }
  },
  mounted() {
    if (this.mealId) {
      this.loadMeal(this.mealId);
    }
  },
  watch: {
    meal(val) {
      const id = (val && val.id) || null;
      if (this.mealId != id) {
        console.log('pushing ', {params: {id}});
        this.lookupById = id;
        this.$router.push({params: {id}});
      }
      if (id) {
        return api.get(`v2/meal/${val.id}`)
          .then(({data}) => this.images = data.images);
      } else {
        this.images = {};
      }
    }
  },
  computed: {
    ...mapState(['imageSlots']),
    mealId() {
      return this.$route.params.id;
    },
    packagedUrl() {
      if (this.packaged) {
        return URL.createObjectURL(this.packaged);
      } else {
        // console.log('packaged url', this.images.packaged);
        return (this.images.packaged && this.images.packaged.url) || '';
      }
    },
    platedUrl() {
      if (this.plated) {
        return URL.createObjectURL(this.plated);
      } else {
        return (this.images.plated && this.images.plated.url) || '';
      }
    },
    otherUrls() {
      return this.otherImages.map(file => ({file, url: URL.createObjectURL(file)}));
    },
    imagesSorted() {
      return Object.values(this.images).sort((a, b) => a.id - b.id);
    }
  },
  methods: {
    loadMeal(id) {
      return api
        .get(`v2/meal/${id}`)
        .then(({data}) => {
          this.meal = data;
          this.images = data.images;
        });
    },
    clearImages() {
      this.packaged = undefined;
      this.plated = undefined;
      this.otherImages = [];
    },
    setPhotoMealId(photoId, newMealId) {
      if (confirm('Are you sure you want to remove this photo?\n\n' +
        'Note: this does not actually delete the image, it just means you will not see it under this meal')) {
        const payload = {photoId, newMealId};
        return api
          .put(`v2/image/meal/assign`, payload)
          .then(() => {
            this.loadMeal(this.mealId);
          })
      }
    },
    setSlotUrl(slot, url) {
      const payload = {}
      payload[slot] = url;
      return api
        .put(`v2/image/meal/${this.mealId}/url`, payload)
        .then(() => {
          this.loadMeal(this.mealId);
        })
    },
    submitForm() {
      this.saving = true;
      const formData = new FormData();
      formData.append('mealId', this.meal.id);
      if (this.packaged) formData.append('packaged', this.packaged);
      if (this.plated) formData.append('plated', this.plated);
      if (this.otherImages.length > 0) this.otherImages.forEach(image => formData.append('other-images', image));
      api.post('v2/image/meal', formData)
        .then((r) => {
          console.log(r);
          this.showSnack = true;
          this.snackMessage = 'Saved';
          this.$nextTick(() => this.clearImages());
        })
        .then(() => this.loadMeal(this.meal.id))
        .catch(e => {
          console.error('fails', e);
        })
        .finally(() => this.saving = false);
      return false;
    },
    formatDate(date) {
      let text = '';
      if (date !== null) {
        // text = moment(date).format('Do MMM YYYY');
        text = moment(date).format(moment.HTML5_FMT.DATE);
      }
      return text;
    },
  },

}
</script>

