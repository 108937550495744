<template>
    <router-link :to="{ name: 'MealSku', params: { sku }}">
        {{ sku ? `${sku}` : '' }}
    </router-link>
</template>
<script>
export default {
    name: 'MealSkuLink',
    props: {
        sku: {}
    }
}
</script>
