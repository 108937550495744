import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mb-6",attrs:{"align":"center","justify":"center","outlined":"","width":"100%"}},[_c(VCol,{attrs:{"md":"auto"}},[_c(VCard,{staticClass:"pa-2",attrs:{"width":"400px"}},[_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"name":"email","label":"Email","id":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"type":"password","label":"Password","aria-autocomplete":"current-password","hint":"At least 8 characters","rules":[function (v) { return !!v || 'Password is required'; }]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.error)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c('div',[_c(VBtn,{attrs:{"loading":_vm.loading,"text":"","outlined":""},on:{"click":_vm.login}},[_vm._v("Log in")])],1),_c(VSpacer)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }