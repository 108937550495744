import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Home from "@/views/Home";
import api from '../api';
import Images from '@/views/Images';
import * as Sentry from '@sentry/vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {requiresAuth: true},
  },
  {
    path: '/component-audit',
    name: 'ComponentAudit',
    components: {
      default: () => import(/* webpackChunkName: "ComponentAudit" */ '../views/ComponentAudit.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: {
        event: 'file'
      }
    }
  },
  {
    path: '/component-waste',
    name: 'ComponentWaste',
    components: {
      default: () => import(/* webpackChunkName: "ComponentAudit" */ '../views/ComponentAudit.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: {
        event: 'waste'
      }
    }
  },
  {
    // remove this after changes
    path: '/component-short',
    name: 'ComponentShort',
    components: {
      default: () => import(/* webpackChunkName: "ComponentAudit" */ '../views/ComponentAudit.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: {
        shortSummary: true,
        event: 'file'
      }
    }
  },
  {
    path: '/audited-short',
    name: 'AuditedShorts',
    components: {
      default: () => import(/* webpackChunkName: "ComponentAudit" */ '../views/ComponentAudit.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: {
        shortSummary: true,
        event: 'file'
      }
    }
  },
  {
    path: '/corporate',
    name: 'CorporateCodes',
    components: {
      default: () => import(/* webpackChunkName: "CorporateCodes" */ '../views/CorporateCodes.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/shipping-rules',
    name: 'ShippingRules',
    components: {
      default: () => import(/* webpackChunkName: "ShippingRules" */ '../views/ShippingRules.vue'),
    },
    meta: {requiresAuth: true},
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/component-comparison',
    name: 'ComponentComparison',
    components: {
      default: () => import(/* webpackChunkName: "ComponentComparison" */ '../views/ComponentComparison.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/container',
    name: 'Container',
    components: {
      default: () => import(/* webpackChunkName: "Container" */ '../views/Container.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/menu',
    name: 'Menu',
    components: {
      default: () => import(/* webpackChunkName: "Menu" */ '../views/Menu.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/menu-edit',
    name: 'MenuEdit',
    components: {
      default: () => import(/* webpackChunkName: "MenuEdit" */ '../views/MenuEdit.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/meal-ingredient-modifier',
    name: 'MealIngredientModifier',
    components: {
      default: () => import(/* webpackChunkName: "MealIngredientModifier" */ '../views/MealIngredientModifier.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/meal-audit',
    name: 'MealAudit',
    components: {
      default: () => import(/* webpackChunkName: "MealAudit" */ '../views/MealAudit.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/meal-designer/:id?',
    name: 'MealDesigner',
    components: {
      default: () => import(/* webpackChunkName: "MealDesigner" */ '../views/MealDesigner.vue'),
    },
    meta: {requiresAuth: true},
  },
  {
    path: '/images/:id?',
    name: 'MealImages',
    component: Images,
    meta: {requiresAuth: true},
  },
  {
    path: '/image-list',
    meta: {requiresAuth: true},
    name: 'MealImageList',
    components: {
      default: () => import(/* webpackChunkName: "MealList" */ '../views/MealList.vue'),
    }

  },
  {
    path: '/meal-filter-list',
    meta: {requiresAuth: true},
    name: 'MealFilterList',
    components: {
      default: () => import(/* webpackChunkName: "MealFilterListList" */ '../views/MealFilterList.vue'),
    }
  },
  {
    path: '/meals/version',
    meta: {requiresAuth: true},
    name: 'MealVersions',
    components: {
      default: () => import(/* webpackChunkName: "MealVersions" */ '../views/MealVersions.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/meals/version/:sku',
    meta: {requiresAuth: true},
    name: 'MealSku',
    components: {
      default: () => import(/* webpackChunkName: "MealSku" */ '../views/MealSku.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/meals/:date',
    meta: {requiresAuth: true},
    name: 'Meals',
    components: {
      default: () => import(/* webpackChunkName: "Meals" */ '../views/Meals.vue'),
      toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/platings',
    meta: {requiresAuth: true},
    name: 'Platings',
    components: {
      default: () => import(/* webpackChunkName: "Platings" */ '../views/Platings.vue'),
    },
  },
  {
    path: '/plating/:date?',
    meta: {requiresAuth: true},
    name: 'PlatingTV',
    components: {
      default: () => import(/* webpackChunkName: "PlatingTV" */ '../views/PlatingTV.vue'),
    },
    props: {
      default: route => ({
        date: route.params.date,
        initialHideNav: true
      })
    }
  },
  {
    path: '/scan/meal/:date?',
    // meta: {requiresAuth: true},
    name: 'MealScan',
    components: {
      default: () => import(/* webpackChunkName: "MealScan" */ '../views/MealScan.vue'),
    },
    props: {
      default: route => ({
        date: route.params.date,
        initialHideNav: true
      })
    }
  },
  {
    path: '/labels/',
    meta: {requiresAuth: true},
    name: 'Labels',
    components: {
      default: () => import(/* webpackChunkName: "Labels" */ '../views/Labels.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {
        isAdmin: true
      }
    }
  },
  {
    path: '/labels-basic/',
    meta: {requiresAuth: true},
    name: 'LabelsBasic',
    components: {
      default: () => import(/* webpackChunkName: "Labels" */ '../views/Labels.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {
        basicView: true
      }
    }
  },
  {
    path: '/multiview/:date/:selection',
    meta: {requiresAuth: true},
    name: 'MealDetailMulti',

    components: {
      meta: {requiresAuth: true},
      default: () => import(/* webpackChunkName: "MealDetailMulti" */ '../views/MealDetailMulti.vue'),
      toolbar: () => import('../components/toolbar/MealButtons')
    }
  },
  {
    path: '/nutrients/:selection',
    meta: {requiresAuth: true},
    name: 'MealNutrientsMulti',

    components: {
      meta: {requiresAuth: true},
      default: () => import(/* webpackChunkName: "MealNutrientsMulti" */ '../views/MealNutrientsMulti.vue'),
      // toolbar: () => import('../components/toolbar/MealButtons')
    }
  },
  {
    path: '/ingredient/nutrients/',
    meta: {requiresAuth: true},
    name: 'IngredientNutrients',
    components: {
      meta: {requiresAuth: true},
      default: () => import(/* webpackChunkName: "IngredientNutrients" */ '../views/IngredientNutrients'),
      // toolbar: () => import('../components/toolbar/MealButtons')
    }
  },
  {
    path: '/meals/:date/:id',
    meta: {requiresAuth: true},
    name: 'MealDetail',
    components: {
      default: () => import(/* webpackChunkName: "MealDetail" */ '../views/MealDetail.vue'),
      toolbar: () => import('../components/toolbar/MealButtons')
    }
  },
  {
    path: '/meal-design/:id?',
    meta: {requiresAuth: true},
    name: 'MealDesign',
    components: {
      default: () => import(/* webpackChunkName: "MealDesign" */ '../views/MealDesign.vue'),
    }
  },
  {
    path: '/meal-process/:id?',
    meta: {requiresAuth: true},
    name: 'MealPrepWork',
    components: {
      default: () => import(/* webpackChunkName: "MealPrepInstructions" */ '../views/MealPrepInstructions.vue'),
    }
  },
  {
    path: '/template/meal/:mealId',
    meta: {requiresAuth: true},
    name: 'MealTemplate',
    components: {
      default: () => import(/* webpackChunkName: "MealTemplate" */ '../views/MealTemplate.vue'),
    },
    props: {default: true}
  },
  {
    path: '/meal-design-admin/:id?',
    meta: {requiresAuth: true},
    name: 'MealDesignAdmin',
    components: {
      default: () => import(/* webpackChunkName: "MealDesign" */ '../views/MealDesign.vue'),
    },
    props: {
      default: {
        isAdmin: true
      }
    }
  },
  {
    path: '/menu-builder/',
    meta: {requiresAuth: true},
    name: 'MenuBuilder',
    components: {
      default: () => import(/* webpackChunkName: "MenuBuilder" */ '../views/MenuBuilder.vue'),
    },
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/streams/',
    meta: {requiresAuth: true},
    name: 'Streams',
    components: {
      default: () => import(/* webpackChunkName: "Streams" */ '../views/Streams.vue'),
    },
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/restrictions/',
    meta: {requiresAuth: true},
    name: 'Restrictions',
    components: {
      default: () => import(/* webpackChunkName: "Restrictions" */ '../views/Restrictions.vue'),
    },
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/schedule/',
    meta: {requiresAuth: true},
    name: 'MealSchedule',
    components: {
      default: () => import(/* webpackChunkName: "MealSchedule" */ '../views/MealSchedule.vue'),
    },
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly
      })
    }
  },
  {
    path: '/template/schedule/:id?',
    meta: {requiresAuth: true},
    name: 'MealScheduleTemplate',
    components: {
      default: () => import(/* webpackChunkName: "MealScheduleTemplate" */ '../views/MealScheduleTemplate.vue'),
    },
    props: {default: true}
  },
  {
    path: '/template/simple/:id?',
    meta: {requiresAuth: true},
    name: 'MealScheduleTemplate',
    components: {
      default: () => import(/* webpackChunkName: "MealScheduleTemplate" */ '../views/MealScheduleTemplate.vue'),
    },
    props: {default: {simpleMode: true}}
  },

  {
    path: '/shopping',
    meta: {requiresAuth: true},
    name: 'Shopping',
    components: {
      default: () => import(/* webpackChunkName: "Shopping" */ '../views/Shopping.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/chopping',
    meta: {requiresAuth: true},
    name: 'Chopping',
    components: {
      default: () => import(/* webpackChunkName: "Chopping" */ '../views/Chopping.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        readOnly: !!route.meta.readOnly,
        showIngredients: true,
        excludeAllergy: true,
      })
    }
  },
  {
    path: '/precut',
    meta: {requiresAuth: true},
    name: 'Precut',
    components: {
      default: () => import(/* webpackChunkName: "Chopping" */ '../views/Chopping.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {
        showPrecut: true,
        excludeAllergy: true
      },
    }
  },
  {
    path: '/task/chopping/:date?',
    meta: {requiresAuth: true},
    name: 'ChoppingTasks',
    components: {
      default: () => import(/* webpackChunkName: "ChoppingTasks" */ '../views/ChoppingTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        date: route.params.date,
      })
    }
  },
  {
    path: '/task/components/:date?',
    meta: {requiresAuth: true},
    name: 'ComponentTasks',
    components: {
      default: () => import(/* webpackChunkName: "ComponentTasks" */ '../views/ComponentTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        date: route.params.date,
        // hideShorts: true
      })
    }
  },
  {
    path: '/task/component-shorts/:date?',
    meta: {requiresAuth: true},
    name: 'ComponentShortTasks',
    components: {
      default: () => import(/* webpackChunkName: "ComponentTasks" */ '../views/ComponentTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        date: route.params.date,
        shortsOnly: true
      })
    }
  },
  {
    path: '/task/packing/:date?',
    meta: {requiresAuth: true},
    name: 'PackingTasks',
    components: {
      default: () => import(/* webpackChunkName: "PackingTasks" */ '../views/PackingTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        date: route.params.date,
      })
    }
  },
  {
    path: '/task/component-weight/:date?',
    meta: {requiresAuth: true},
    name: 'ComponentWeighTasks',
    components: {
      default: () => import(/* webpackChunkName: "ComponentWeighTasks" */ '../views/ComponentWeighTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        date: route.params.date,
      })
    }
  },
  {
    path: '/task/plating/',
    meta: {requiresAuth: true},
    name: 'PlatingTasks',
    components: {
      default: () => import(/* webpackChunkName: "PlatingTasks" */ '../views/PlatingTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      // default: route => ({
      //   date: route.params.date,
      // })
    }
  },
  {
    path: '/task/plating2/',
    meta: {requiresAuth: true},
    name: 'PlatingTasks2',
    components: {
      default: () => import(/* webpackChunkName: "PlatingTasks2" */ '../views/PlatingTasks2'),
    },
    props: {}
  },
  {
    path: '/task/plating/allergy',
    meta: {requiresAuth: true},
    name: 'AllergyPlatingTasks',
    components: {
      default: () => import(/* webpackChunkName: "PlatingTasks" */ '../views/PlatingTasks'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {
        platingMode: 'Allergy'
      }
    }
  },
  {
    path: '/tasks',
    meta: {requiresAuth: true},
    name: 'TaskReport',
    components: {
      default: () => import(/* webpackChunkName: "TaskReport" */ '../views/TaskReport'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
  },
  {
    path: '/component-demand',
    meta: {requiresAuth: true},
    name: 'Component Demand',
    components: {
      default: () => import(/* webpackChunkName: "Chopping" */ '../views/Chopping.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {showComponents: true, showSummary: true, excludeAllergy: true},
    }
  },
  {
    path: '/component-prep',
    meta: {requiresAuth: true},
    name: 'ComponentPrep',
    components: {
      default: () => import(/* webpackChunkName: "Chopping" */ '../views/Chopping.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {showComponents: true, excludeAllergy: true},
    }
  },
  {
    path: '/component-prep-2',
    meta: {requiresAuth: true},
    name: 'ComponentPrep2',
    components: {
      default: () => import(/* webpackChunkName: "ComponentPrep2" */ '../views/ComponentPrep2.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {showComponents: true, excludeAllergy: true},
    }
  },
  {
    path: '/orders',
    meta: {requiresAuth: true},
    name: 'Orders',
    components: {
      default: () => import(/* webpackChunkName: "Orders" */ '../views/Orders.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/order-edit',
    meta: {requiresAuth: true},
    name: 'OrderEdit',
    components: {
      default: () => import(/* webpackChunkName: "OrderEdit" */ '../views/OrderEdit.vue'),
    }
  },
  {
    path: '/customers',
    meta: {requiresAuth: true},
    name: 'Customers',
    components: {
      default: () => import(/* webpackChunkName: "Customers" */ '../views/Customers.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/customers-finance',
    meta: {requiresAuth: true},
    name: 'CustomersFinance',
    components: {
      default: () => import(/* webpackChunkName: "Customers" */ '../views/Customers.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {default: {finance: true, canBulkEdit: true}}
  },
  {
    path: '/customer-cancel-reasons',
    meta: {requiresAuth: true},
    name: 'CustomerCancelReasons',
    components: {
      default: () => import(/* webpackChunkName: "CustomerCancelReasons" */ '../views/CustomerCancelReasons.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },

  {
    path: '/plans',
    meta: {requiresAuth: true},
    name: 'Plans',
    components: {
      default: () => import(/* webpackChunkName: "Plans" */ '../views/Plans.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
  },
  {
    path: '/plans/:id',
    meta: {requiresAuth: true},
    name: 'PlanEdit',
    components: {
      default: () => import(/* webpackChunkName: "PlanEdit" */ '../views/PlanEdit.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        id: route.params.id,
      }),
    }
  },
  {
    path: '/customers/:id',
    meta: {requiresAuth: true},
    name: 'Customer',
    components: {
      default: () => import(/* webpackChunkName: "Customer" */ '../views/Customer.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        id: route.params.id,
        canEditSubaccounts: !route.meta.readOnly,
        canEditPassword: true,
        canEditLoginDisabled: true,
      })
    }
  },
  {
    path: '/customers-finance/:id',
    meta: {requiresAuth: true},
    name: 'CustomerFinance',
    components: {
      default: () => import(/* webpackChunkName: "Customer" */ '../views/Customer.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: route => ({
        id: route.params.id,
        canEditPassword: true,
        canEditPlan: true,
        canEditLoginDisabled: true,
        canEditSubaccounts: !route.meta.readOnly,
        canDeleteUser: true,
        canChangeAdmin: true,
        canEditCommission: true,
      })
    }
  },
  {
    path: '/feedback',
    meta: {requiresAuth: true},
    name: 'Feedback',
    components: {
      default: () => import(/* webpackChunkName: "Feedback" */ '../views/Feedback.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/components',
    meta: {requiresAuth: true},
    name: 'Components',
    components: {
      default: () => import(/* webpackChunkName: "Components" */ '../views/Components.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/ingredients',
    meta: {requiresAuth: true},
    name: 'Ingredients',
    components: {
      default: () => import(/* webpackChunkName: "Ingredients" */ '../views/Ingredients.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/components/:id',
    meta: {requiresAuth: true},
    name: 'ComponentDetail',
    components: {
      default: () => import(/* webpackChunkName: "ComponentDetail" */ '../views/ComponentDetail.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    }
  },
  {
    path: '/components-admin/:id?',
    meta: {requiresAuth: true},
    name: 'ComponentDetailAdmin',
    components: {
      default: () => import(/* webpackChunkName: "ComponentDetail" */ '../views/ComponentDetail.vue'),
      // toolbar: () => import('../components/toolbar/DateToolbar')
    },
    props: {
      default: {
        isAdmin: true
      }
    }
  },
  {
    path: '/users',
    name: 'Users',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue'),
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Roles" */ '../views/Roles.vue'),
    }
  },
  {
    path: '/packing',
    name: 'Packing',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Packing" */ '../views/Packing.vue'),
    }
  },
  {
    path: '/packing-admin',
    name: 'PackingAdmin',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Packing" */ '../views/Packing.vue'),
    },
    props: {default: {isAdmin: true}}
  },
  {
    path: '/delivery',
    name: 'Delivery',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Packing" */ '../views/Packing.vue'),
    },
    props: {default: {isDelivery: true}}
  },
  {
    path: '/delivery-admin',
    name: 'DeliveryAdmin',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Packing" */ '../views/Packing.vue'),
    },
    props: {default: {isDelivery: true, isAdmin: true}}
  },
  {
    path: '/delivery2',
    name: 'Delivery2',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Shipments" */ '../views/Shipments.vue'),
    },
    props: {default: {isDelivery: true}}
  },
  {
    path: '/delivery2-admin',
    name: 'DeliveryAdmin2',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Packing" */ '../views/Shipments.vue'),
    },
    props: {default: {isDelivery: true, isAdmin: true}}
  },
  {
    path: '/metrics',
    name: 'Metrics',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Metrics" */ '../views/Metrics.vue'),
    }
  },
  {
    path: '/drivers',
    name: 'Drivers',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Drivers" */ '../views/Drivers.vue'),
    }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    meta: {requiresAuth: true},
    components: {
      default: () => import(/* webpackChunkName: "Invoices" */ '../views/Invoices.vue'),
    },
    props: {default: {isAdmin: true}}
  },
  {
    path: '/sorry',
    name: 'NotAllowed',
    components: {
      default: () => import(/* webpackChunkName: "NotAllowed" */ '../views/NotAllowed.vue'),
    }
  },
  {
    path: '*',
    redirect: {
      name: 'Home',
    }
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// set page title
router.beforeEach((to, from, next) => {
  try {
    let prefix;
    if (!api.isProductionBuild) {
      prefix = (api.isProductionApi ? 'LIVE' : 'TEST') + ' :: ';
    } else {
      prefix = 'Fed Kitchen :: ';
    }

    const page = to.matched.slice().reverse()[0];
    document.title = prefix + page.name;
  } catch (e) {
    console.error('failed to set page title', e);
  }
  return next();
});


router.beforeEach((to, from, next) => {
  //console.debug('router.beforeEach', { to, from});
  const token = localStorage.getItem('token');
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //console.debug('router.beforeEach', { to, from, requiresAuth});
  if (requiresAuth) {
    if (!token && to.name !== 'Login') {
      console.debug('needs token so redirect to login');
      return next({name: 'Login', replace: true});
    } else {
      return next();
    }
  } else {
    return next();
  }
});


// apply page restrictions
router.beforeResolve(function checkPageRestrictions(to, from, next, attempts) {
  try {
    const page = to.matched.slice().reverse()[0];
    if (page.meta && !page.meta.requiresAuth) {
      return next();
    }
    if (!router.app.$store) {
      console.warn('beforeResolve $store not found');
      return next();
    }

    const user = router.app.$store.getters.getCurrentUser;
    // if (!user || user.none) {
    //   return next();
    // }

    if (!user || !user.roleDetail) {
      //this is a pretty ugly hack because roleDetail has not been loaded yet
      console.info(`role detail not loaded, will try again. ${attempts} attempts`);
      attempts = attempts || 0;
      if (attempts < 20) {
        setTimeout(() => {
          checkPageRestrictions(to, from, next, ++attempts);
        }, 100 * attempts);
      } else {
        alert('too many attempts to check role, please report this problem');
      }
      return;
    }

    // console.log('user', user);
    const {roleDetail: {readPages, writePages}} = user;
    //console.log('beforeResolve user/page', {readPages, writePages, page});
    // const skipCheck = false;
    // if (skipCheck) {
    //   next();
    // } else
    if (readPages.length > 0 || writePages.length > 0) {
      if (writePages.includes(page.name)) {
        console.log('write access to', page.name);
        page.meta.writeAccess = true;
        next();
      } else if (readPages.includes(page.name)) {
        console.log('read access to', page.name);
        page.meta.readOnly = true;
        next();
      } else {
        console.log('no access to' + page.name, page);
        const message =
          `${user.email} has the role ${user.roleName} which does not have access to ${page.name}\n\n` +
          `To access this page, this permission should be added to the role: ${page.path}`;

        if (user.roleName === 'admin') {
          alert(message);
          alert('because you are an admin, we will let you pass');
          page.meta.writeAccess = true;
          next();
        } else {
          alert(message);
          next(from);
        }
      }
    } else {
      // no page restrictions set, so no check
      next();
    }
  } catch (e) {
    console.error('failed to check role setting', e);
    alert('failure doing role check - this should not happen.  please report this issue');
  }
});


let ignoreChunkError = false;
router.onError(err => {
  if (/Loading chunk *.+ failed/i.test(err.message)) {
    if (ignoreChunkError) {
      console.error('chunk error, but ignoring', err);
      Sentry.captureException(err);
    } else {
      if (confirm('it appears the application has been updated, click ok to reload the application')) {
        window.location.reload()
      } else {
        if (confirm('would you like to ignore these errors?  doing so may mean the application does not work correctly')) {
          ignoreChunkError = true;
        }
      }
    }
  } else {
    throw err;
  }
});

export default router
