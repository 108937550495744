import api from '@/api';
import {convertIngredient, convertIngredientBack, ensureInt} from '@/store/utils';

const skipApi = false;

async function deleteMealIngredient(ingredient) {
  console.log('deleting', ingredient);
  if (ingredient.mealid) {
    const deleteUrl = (ingredient.component_id)
      ? `meals/${ingredient.mealid}/${ingredient.foodid}?component=${ingredient.component_id}`
      : `meals/${ingredient.mealid}/${ingredient.foodid}`

    return skipApi || await api.delete(deleteUrl);
  } else {
    return skipApi || await api.delete(`v2/component/${ingredient.component_id}/ingredients/${ingredient.foodid}`);
  }

}

export default {
  async createComponent({commit}, component) {

    //TODO: put this in state
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userId = user.id;

    component.description = component.description || '';
    const payload = {
      // ugh - api requires json attribute 'data'
      data: {
        userId,
        ...component
      }
    };
    console.log('create component api components/create', payload);
    const response = await api.post('components/create', payload);
    console.log('create component api ', response.data);

    const {id} = response.data;
    component.component_id = id;

    commit('SET_COMPONENT', {id, component});
    return {id, component};
  },
  async updateComponent({commit}, {id, component}) {
    console.log('update component', component);
    const {name, monosized, small_ratio, large_ratio, notes, category, description, verified} = component;
    const payload = {
      name, monosized, small_ratio, large_ratio, notes, category, description, verified
    }
    console.log('update component api components' + id, payload);
    const response = await api.put(`components/${id}`, payload);
    console.log('update component api ', response.data);
    commit('SET_COMPONENT', {id, component});
  },
  async updateComponentTags({commit}, {id, tags}) {
    console.log('update component', id);
    const payload = {tags};
    console.log('update component tags' + id, payload);
    const response = await api.put(`v2/component/${id}/tags`, payload);
    console.log('update component api ', response.data);
    commit('SET_COMPONENT', {id, component: response.data});
  },

  async createComponentTag({commit}, tagName) {
    const response = await api.post('v2/component/tags', {
      name: tagName
    });
    if (response.status === 200) {
      commit('ADD_COMPONENT_TAG', {componentTag: response.data});
    }
  },
  // async addIngredient({commit}, { ingredient}){
  //   throw new Error('use replace ingredient');
  // },
  async removeIngredient({commit}, {ingredient}) {
    commit('DELETE_MEAL_INGREDIENT', {ingredient});
    if (ingredient.foodid) {
      const deleteResponse = await deleteMealIngredient(ingredient);
      console.log('delete api response', deleteResponse.data);
    }
  },
  async replaceIngredientFood({commit}, {oldIngredient, newIngredient}) {
    function showDetail(i) {
      return i && `${i.name} ${i.foodid} ${i.component_id} `
    }

    console.log(`updateIngredientFood - replacing ${showDetail(oldIngredient)} with ${showDetail(newIngredient)}`, {
      oldIngredient,
      newIngredient
    });

    if (oldIngredient && oldIngredient.foodid) {
      // if there was an old ingredient we are replacing
      const deleteResponse = await deleteMealIngredient(oldIngredient);
      console.log('delete api response', deleteResponse.data);
      commit('SET_MEAL_INGREDIENT', {
        ingredient: oldIngredient,
        update: newIngredient
      });
    } else {
      // otherwise, add this ingredient
      commit('ADD_MEAL_INGREDIENT', {ingredient: newIngredient});

      if (oldIngredient) {
        // and delete the old one from the store - this is typically exists only in-memory (not saved to db)
        commit('DELETE_MEAL_INGREDIENT', {ingredient: oldIngredient});
      }
    }

    if (newIngredient.foodid) {
      const createPayload = {
        componentId: newIngredient.component_id,
        portionBasis: newIngredient.portion_basis,
        portionMultiplier: newIngredient.portion_multiplier
      };


      console.log(`api post meals/${newIngredient.mealid}/${newIngredient.foodid}`, {createPayload});
      let response;
      if (newIngredient.mealid) {
        response = skipApi || await api.post(`meals/${newIngredient.mealid}/${newIngredient.foodid}`, createPayload);
        if (response.data) {
          console.log('api response', response.data);
          const [{id}] = response.data;
          commit('SET_MEAL_INGREDIENT', {ingredient: newIngredient, update: {id}});
        } else if (!skipApi) {
          console.warn('no api response!');
        }
      } else if (newIngredient.component_id) {
        response = skipApi || await api.post(`v2/component/${newIngredient.component_id}/ingredient/${newIngredient.foodid}`, createPayload);
        if (response.data) {
          console.log('api response', response.data);
          // const [{id}] = response.data;
          commit('SET_MEAL_INGREDIENT', {ingredient: newIngredient, update: newIngredient});
        }
      }

    } else {
      console.log('no api call yet as foodid not set');
    }


    console.log('finished existingIngredient', newIngredient);
  },
  async replaceIngredient({dispatch, commit}, {mealId, componentId, oldFoodId, newFoodId}) {
    const payload = {
      oldFoodId,
      newFoodId,
      componentId
    }
    const response = await api.put(`v2/meal/${mealId}/ingredients/replace`, payload);
    console.log('api response', response.data);
    // const oldIngredient = getters.getMealIngredient({mealid: mealId, foodid: oldFoodId, component_id: componentId});
    // const newIngredient = getters.getF
    // commit('DELETE_MEAL_INGREDIENT',{ ingredient: {mealid: mealId, foodid: oldFoodId, component_id: componentId}});
    // commit('ADD_MEAL_INGREDIENT', { ingredient : { ...JSON.parse(JSON.stringify(oldIngredient)), foodid: newFoodId}});
    commit('CLEAR', {property: 'ingredients', id: mealId});
    return dispatch('fetchMealIngredients', mealId);
  },

  async updateIngredientAmount({commit, getters}, {ingredient, update}) {
    ingredient = Object.assign({}, ingredient, update);
    const ingredientUpdated = convertIngredientBack(ingredient)
    const payload = {
      componentId: ingredientUpdated.component_id,
      portionBasis: ingredientUpdated.portion_basis || ingredientUpdated.measureid,
      portionMultiplier: ingredientUpdated.portion_multiplier,
      // TC: shopping multiplier is not saved in meal ingredient, see updatePercentChange below instead
      //shoppingmultiplier: ingredientUpdated.shoppingmultiplier
    };
    if (ingredient.foodid) {
      console.log('savers: updateIngredientAmount converted', {ingredientUpdated, payload});
      if (ingredient.mealid) {
        const response = await api.put(`meals/${ingredient.mealid}/${ingredient.foodid}`, payload);
        console.log('api response', response.data);
      } else if (ingredient.component_id) {
        // no meal id, so update component amount
        const response = await api.put(`v2/component/${ingredient.component_id}/ingredient/${ingredient.foodid}`, payload);
        console.log('api response', response.data);
      }
    } else {
      console.log('no api update, as foodid not set');
    }
    const existingIngredient = getters.getMealIngredient(ingredient);
    console.log('updateIngredientAmount: existingIngredient', existingIngredient);
    if (existingIngredient) {
      commit('SET_MEAL_INGREDIENT', {ingredient: existingIngredient, update: convertIngredient(ingredientUpdated)});
    }
  },
  async updateIngredientTags(opts, {ingredient, update}) {
    ingredient = Object.assign({}, ingredient, update);
    const promises = [];
    if (update.set) {
      if (update.add || update.remove) {
        throw new Error('cannot use set with add and remove');
      }
      const payload = {
        food: ingredient.foodid,
        tagIds: update.set
      };
      promises.push(api.put('tags/tagfood', payload));
    } else {
      const tagsToAdd = update.add || [];
      const tagsToRemove = update.remove || [];
      for (const tag of tagsToAdd) {
        const payload = {
          food: ingredient.foodid,
          tag
        };
        promises.push(api.post('tags/tagfood', payload));
      }
      for (const tag of tagsToRemove) {
        const payload = {
          foodId: ingredient.foodid,
          tagId: tag
        };
        promises.push(api.delete(`tags/food`, {data: payload}))
      }
    }

    await promises;
    // const existingIngredient = getters.getMealIngredient(ingredient);
    // console.log('updatePercentChange existingIngredient', {ingredient: existingIngredient, update: ingredientUpdated});
    // commit('SET_MEAL_INGREDIENT', {ingredient: existingIngredient, update: ingredientUpdated});

  },
  async updatePercentChange({commit, getters}, {ingredient, update}) {
    ingredient = Object.assign({}, ingredient, update);
    const ingredientUpdated = convertIngredientBack(ingredient)
    const payload = {
      shoppingmultiplier: ingredientUpdated.shoppingmultiplier
    };
    if (ingredient.foodid) {
      console.log('savers: updatePercentChange converted', {ingredientUpdated, payload});
      const response = await api.post(`foodname/${ingredient.foodid}/partial`, payload);
      console.log('api response', response.data);
    } else {
      console.log('no api update, as foodid not set');
    }
    if (ingredient.mealid) {
      const existingIngredient = getters.getMealIngredient(ingredient);
      console.log('updatePercentChange existingIngredient', {
        ingredient: existingIngredient,
        update: ingredientUpdated
      });
      commit('SET_MEAL_INGREDIENT', {ingredient: existingIngredient, update: ingredientUpdated});
    }
  },
  async updateIngredientPartial({commit}, {ingredient, update}) {
    ingredient = Object.assign({}, ingredient, update);
    const payload = update;
    if (ingredient.foodid) {
      console.log('savers: updateIngredientPartial', {payload});
      // const response = await api.post(`foodname/${ingredient.foodid}/partial`, payload);
      const response = await api.put(`v2/food/${ingredient.foodid}`, payload);
      console.log('api response', response.data);
    } else {
      console.log('no api update, as foodid not set', ingredient);
    }
    commit('UPDATE_INGREDIENT', {foodId: ingredient.foodid, update});
    return ingredient;
  },
  async updateIngredientCategories({commit}, {ingredient, update}) {
    ingredient = await this.updateIngredientPartial({commit}, ingredient, update);
    console.log('updateIngredientCategories changes', {foodId: ingredient.foodid, categories: update.categories});
    // commit('UPDATE_INGREDIENT', {foodId: ingredient.foodid, update: {categories: update.categories}});
  },

  async updateIngredient({commit}, {ingredient, update}) {
    console.log('updating ingredient', {ingredient, update})
    const ingredientUpdated = convertIngredientBack(ingredient)
    const payload = {
      componentId: ingredientUpdated.component_id,
      portionBasis: ingredientUpdated.portion_basis,
      portionMultiplier: ingredientUpdated.portion_multiplier
    };
    console.log('savers: updatingIngredient calling api', {ingredientUpdated, payload});
    const response = await api.put(`meals/${ingredient.mealid}/${ingredient.foodid}`, payload);
    commit('SET_MEAL_INGREDIENT', {ingredient, update});
    console.log('savers: updatingIngredient response ', response.data);
  },
  async updateIngredientAttribute({commit}, {ingredient, update}) {
    console.log('updating ingredient attribute', {ingredient, update})
    let {component_id, mealid, foodid} = ingredient;
    component_id = component_id ? ensureInt(component_id) : null;
    mealid = ensureInt(mealid);
    foodid = ensureInt(foodid);
    const response = await api.post(`v2/meal/${ingredient.mealid}/ingredients`, {
      ...update,
      component_id,
      mealid,
      foodid
    });
    commit('SET_MEAL_INGREDIENT', {ingredient, update});
    console.log('savers: updatingIngredient response ', response.data);
  },
  async copyMeal({getters, dispatch}, {mealId}) {
    console.log('copy meal', mealId);
    const meal = getters.getMeal(mealId);
    const ingredients = getters.getMealIngredients(mealId);
    const payload = {
      meal: {
        name: meal.name,
        diet: meal.diet,
        description: meal.description
      },
      foods: ingredients.map(i => ({
        foodid: i.foodid,
        portion_basis: i.portion_basis,
        portion_multiplier: i.portion_multiplier,
        component: (i.component_id) || null
      }))
      //   [
      //   { food.foodid, food.portion_basis, food.portion_multiplier, food.component || null}
      // ]
    };
    console.log('copy meal meals/create', {ingredients, payload});
    let response = await api.post('meals/create', payload);
    console.log('copy meal response', response.data);
    const [{id}] = response.data;
    response = await api.post('meals/version', {main: mealId, submeal: id});
    console.log('set version ', {main: mealId, submeal: id, response: response.data});
    await dispatch('fetchSubMeals', {id: mealId, force: true});
  },
  async deleteMeal({commit}, {mealId}) {
    await api.delete(`meals/delete/${mealId}`);
    commit('CLEAR', {property: 'meals', id: mealId});
    commit('CLEAR', {property: 'ingredients', id: mealId});
  },
  async updateMeal({commit}, {mealId, update}) {
    if (!mealId) {
      throw new Error('no meal id passed to updateMeal.');
    }
    console.log('updating meal', mealId);
    const payload = {
      meal: {
        ...update,
        id: mealId
      }
    };
    const {data: result} = await api.put(`v2/meal/${mealId}`, payload);
    console.log('update result', result);
    commit('SET_MEAL', {meal: result});
  },
  async updateOrder({commit, getters}, {date, uid, orderId, update}) {
    const payload = {
      orderUpdate: update
    };
    const {data: result} = await api.put(`v2/order/${date}/${uid}/${orderId}`, payload);
    console.log('update result', result);
    const order = getters.getOrder(orderId);
    commit('SET_ORDER', {id: orderId, order: {...order, ...update}});
  },
  async exportMeal({commit}, id) {
    const payload = {meal: {id}};
    try {
      await api.post(`meals/export/`, payload);
      commit('SET_EXPORT_STATUS', {id, status: {isExported: true}});
    } catch (e) {
      alert('there was a problem exporting');
    }

  },
  async updatePlan({commit, state}, {id, update}) {
    console.log('saving plan', id, update);

    function isString(v) {
      return typeof v === 'string';
    }

// convert any strings to numbers
    function ensurePlanPricesAreNumbers(plan) {
      if (isString(plan.large_price)) {
        console.log('converting large price', plan.large_price, plan);
        plan.large_price = Number(plan.large_price);
      }
      (plan.ranges || [])
        .forEach(r => {
          if (isString(r.max)) {
            console.log('converting max', r.max, r, plan);
            r.max = r.max !== '' ? Number(r.max) : null;
          }
          if (isString(r.min)) {
            console.log('converting min', r.min, r, plan);
            r.min = r.min !== '' ? Number(r.min) : null;
          }
          if (isString(r.price)) {
            console.log('converting r.price', r.price, r, plan);
            r.price = Number(r.price);
          }
        });
    }

    ensurePlanPricesAreNumbers(update);

    return api.post(`v2/plans/${id}`, update)
      .then(() => {
        const plans = {...state.plans};
        plans[id] = update;
        commit('SET_PLANS', {plans});
        console.log('success', plans);
      })
      .catch(e => {
        console.warn('fail', e);
      });
  },

}

