import Vue from 'vue';

function setOrder(state, id, order) {
  if (id) {
    Vue.set(state.ordersById, id, order);
  } else {
    console.warn('no id on order ', order);
  }
}

export default {
  SET_DATE(state, date) {
    state.date = date;
  },
  CLEAR(state, {property, id}) {
    Vue.set(state[property], id, undefined);
    Vue.set(state.loaded[property], id, false);
  },
  SET_ORDER(state, {id, order}) {
    setOrder(state, id, order);
  },
  SET_CURRENT_USER(state, {user, token}) {
    Vue.set(state, 'user', user);
    Vue.set(state, 'token', token);
  },
  SET_CUSTOMERS(state, customers) {
    Vue.set(state, 'customers', customers);
  },
  SET_FEEDBACK(state, feedback) {
    Vue.set(state, 'feedback', feedback);
  },
  SET_ORDERS(state, {orders, key}) {
    if (key) {
      Vue.set(state.orders, key, orders);
    }
    if (Array.isArray(orders)) {
      orders.forEach(o => {
        setOrder(state, o.id, o);
      })
    } else if (!key) {
      console.warn('SET_ORDERS called but no key and orders are not array, so nothing is saved', {key, orders});
    }

  },
  SET_MEALS(state, {meals, date}) {
    if (date) {
      Vue.set(state.meals, date, meals);
    }
    meals.forEach(meal => {
      Vue.set(state.meals, meal.id, meal);
    });
  },
  SET_SUB_MEALS(state, {id, meals}) {
    Vue.set(state.submeals, id, meals);
    for (const meal of meals) {
      Vue.set(state.parentMeals, meal.id, id);
    }
  },
  SET_DIETS(state, {diets}) {
    Vue.set(state, 'diets', diets);
  },
  SET_NUTRIENTS(state, {nutrients}) {
    Vue.set(state, 'nutrients', nutrients);
  },
  SET_SKUS(state, skus) {
    Vue.set(state, 'skus', skus);
  },
  SET_COMPONENT_BATCHES(state, {key, value}) {
    Vue.set(state.componentBatches, key, value);
  },
  SET_RESTRICTIONS(state, {restrictions}) {
    Vue.set(state, 'restrictions', restrictions);
  },
  SET_FOOD_TAGS(state, {tags}) {
    Vue.set(state, 'food_tags', tags);
  },
  SET_INVOICE(state, {key, invoice}) {
    state.invoices[key] = invoice;
  },
  SET_EXPORT_STATUS(state, {id, status}) {
    Vue.set(state.exportStatus, id, status);
  },
  SET_COMPONENTS(state, {components}) {
    Vue.set(state, 'components', components);
  },
  SET_COMPONENT(state, {id, component}) {
    Vue.set(state.components, id, component);
  },
  SET_COMPONENT_TAGS(state, {componentTags}) {
    Vue.set(state, 'componentTags', componentTags);
    state.componentTags.sort((a, b) => a.name.localeCompare(b.name));
  },
  ADD_COMPONENT_TAG(state, {componentTag}) {
    state.componentTags.push(componentTag);
    state.componentTags.sort((a, b) => a.name.localeCompare(b.name));
    console.log('state.componentTags', state.componentTags);
  },

  SET_STREAMS(state, {streams}) {
    Vue.set(state, 'streams', streams);
    console.log('set streams', {streams});
  },
  SET_PLANS(state, {plans}) {
    state.plans = plans;
  },
  SET_SHIPPING(state, {shipping}) {
    state.shipping = shipping;
  },
  SET_MENU(state, {date, menu}) {
    state.menus[date] = menu;
  },
  SET_MEAL(state, {id, meal}) {
    Vue.set(state.meal, id, meal);
  },
  SET_MEAL_NAME_AND_DIET(state, {id, meal}) {
    Vue.set(state.mealNameAndDiet, id, meal);
  },
  SET_CONTAINERS(state, {containers}) {
    Vue.set(state, 'containers', containers);
  },
  SET_INGREDIENTS(state, {id, ingredients}) {
    Vue.set(state.ingredients, id, ingredients);
  },
  // eslint-disable-next-line no-unused-vars
  SET_MEAL_INGREDIENT(state, {ingredient, update}) {
    const mealId = ingredient.mealid || `C${ingredient.component_id}`;
    const mealIngredients = state.ingredients[mealId];
    if (!mealIngredients) {
      // alert(`error: meal ${mealId} not loaded correctly.  this could be a bug, please reload and try again.  if that fails, tell tom`);
      return;
    }
    const index = mealIngredients.findIndex(i => i.foodid == ingredient.foodid && i.component_id == ingredient.component_id);
    // Vue.set(mealIngredients, index, Object.assign({}, ingredient, update));
    Vue.set(mealIngredients, index, Object.assign(ingredient, update));
    console.log('SET_MEAL_INGREDIENT - updated', {index, ingredient, update});
  },
  SET_INGREDIENT_CATEGORIES(state, {foodId, categories}) {
    // this is a bit tedious, since ingredients were store by mealId, need to find them
    Object.values(state.ingredients)
      .forEach(mealIngredients =>
        mealIngredients
          .filter(i => i.foodid == foodId)
          .forEach(i => i.categories = categories)
      );
  },
  UPDATE_INGREDIENT(state, {foodId, update}) {
    // this is a bit tedious, since ingredients were store by mealId, need to find them
    Object.values(state.ingredients)
      .forEach(mealIngredients =>
        mealIngredients
          .filter(i => i.foodid == foodId)
          .forEach(i => Object.keys(update)
            .forEach(prop => i[prop] = update[prop])
          )
      );
  },
  ADD_MEAL_INGREDIENT(state, {ingredient}) {
    const mealId = ingredient.mealid;
    if (!state.ingredients[mealId]) {
      state.ingredients[mealId] = [];
    }
    const mealIngredients = state.ingredients[mealId];
    console.log('ADD_MEAL_INGREDIENT - ', ingredient);
    console.log('ADD_MEAL_INGREDIENT - before', mealIngredients.map(i => i.foodid));
    const index = mealIngredients.findIndex(i => i.foodid == ingredient.foodid && i.component_id == ingredient.component_id);
    if (index === -1) {
      mealIngredients.push(ingredient);
    } else {
      console.log('cannot add ingredient to meal, it already exists at index', {index, mealId, ingredient});
      //Vue.set(state.ingredients[mealId],index,ingredient);
    }
    console.log('ADD_MEAL_INGREDIENT - after', mealIngredients.map(i => i.foodid));
  },

  DELETE_MEAL_INGREDIENT(state, {ingredient}) {
    const mealId = ingredient.mealid;
    if (!state.ingredients[mealId]) {
      state.ingredients[mealId] = [];
    }
    const mealIngredients = state.ingredients[mealId];
    // console.log('DELETE_MEAL_INGREDIENT - before', mealIngredients.map(i => i.foodid));
    const index = mealIngredients.findIndex(i => i.foodid == ingredient.foodid && i.component_id == ingredient.component_id);
    if (index > -1) {
      mealIngredients.splice(index, 1)
      // console.log('DELETE_MEAL_INGREDIENT - after', mealIngredients.map(i => i.foodid));
    } else {
      // console.log('DELETE_MEAL_INGREDIENT - warning, could not find index ', {ingredient, mealIngredients});
    }
  },
  SET_HIDE_TOP_NAV(state, {hideTopNav}) {
    state.hideTopNav = hideTopNav;
  }
};
