import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters';
import fetchers from '@/store/actions/fetchers';
import savers from '@/store/actions/savers';
import mutations from './mutations';
import moment from 'moment';
// import {webSocketPlugin} from '../socket';

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [webSocketPlugin],
  state: {
    dates: [],
    date: moment().format(moment.HTML5_FMT.DATE),
    user: {},
    token: false,
    orders: {},
    orderNote: 'Please note that your order schedule repeats weekly. Please make any changes before Thursday at midnight.',
    ordersById: {},
    meals: {},
    meal: {},
    ingredients: {},
    diets: null,
    nutrients: null,
    customers: null,
    feedback: null,
    restrictions: null,
    food_tags: null,
    summary: {},
    componentTags: [],
    components: {
      // this is rather hackish.  this is the default
      // component used for ingredients in a meal which
      // do not have a component.  i may come to regret
      // this if people try to update it!
      null: {
        id: 'general',
        isDefault: true,
        name: 'General ingredients',
        small_ratio: 0.75,
        large_ratio: 1.25
      },
    },
    submeals: {},
    parentMeals: {},
    users: {},
    streams: {},
    plans: {},
    shipping: {},
    exportStatus: {},
    mealRestrictions: {},
    ingredientRestrictions: {},
    mealNameAndDiet: {},
    containers: [],
    timesOfDay: [
      {id: 1, name: 'breakfast'},
      {id: 2, name: 'lunch'},
      {id: 3, name: 'dinner'},
      {id: 4, name: 'extras'},
    ],
    productionPriorities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear'],
    imageSlots: {
      packaged: 'Package',
      plated: 'Plated',
      closeup: 'Close Up'
    },
    defaultTeams: 'sauciers,protein,veg,starch,misc'.split(','),
    timers: {},
    skus: [],
    menus: {},
    invoices: {},
    componentBatches: {},
    loaded: {
      componentBatches: {},
      invoices: {},
      menus: {},
      orders: {},
      ordersById: {},
      meals: {},
      meal: {},
      ingredients: {},
      diets: null,
      restrictions: null,
      summary: {},
      components: {},
      containers: null,
      submeals: {},
      users: {},
      streams: null,
      exportStatus: {},
      mealRestrictions: {},
      mealNameAndDiet: {},
      skus: null
    },
    hideTopNav: false
  },
  getters,
  mutations,
  actions: {
    ...fetchers,
    ...savers,
  },
  modules: {}
})
