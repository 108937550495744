<template>
  <v-container >
    <v-row align="center" justify="center" class="mb-6"  outlined width="100%">
      <v-col md="auto">
        <v-card width="400px" class="pa-2">
          <v-card-text>
            <v-form>
              <v-text-field
                  name="email"
                  label="Email"
                  id="email"
                  v-model="email"
              ></v-text-field>
              <v-text-field
                  type="password"
                  label="Password"
                  aria-autocomplete="current-password"
                  hint="At least 8 characters"
                  :rules="[v => !!v || 'Password is required']"
                  v-model="password"
                  @keyup.native.enter="login"
              ></v-text-field>
              <p v-if="error" style="color:red;">{{ error }}</p>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn :loading="loading" v-on:click="login" text outlined>Log in</v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api';
import {mapActions} from 'vuex';

export default {
  name: 'Login',

  data: () => ({
    loading: false,
    email: '',
    password: '',
    error: ''
  }),

  methods: {
    ...mapActions([
        'fetchCurrentUser'
    ]),
    async login() {
      try {
        this.loading = true;
        const response = await api.post('users/login', {
          email: this.email,
          password: this.password
        });
        console.log('logged in', response.data);
        api.setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        // await this.$store.dispatch('initData');
        await this.fetchCurrentUser();
        this.$router.replace('/');
      } catch (e) {
        // this.displaySnackbar(e.message, 'error');
        window.alert(e.message);
      } finally {
        this.loading = false;
      }
    }

  }
}

// @Component
// export default class Login extends Vue {
//     private loading: boolean = false;
//     private email: string = '';
//     private password: string = '';
//     private error: string = '';
//     private e1: boolean = true;
//     private snackbar: boolean = false;
//     private snackbarText: string = '';
//     private snackbarColor: string = '';
//
//
//     private displaySnackbar(msg: string, color: string) {
//         this.snackbarText = msg;
//         this.snackbarColor = color;
//         this.snackbar = true;
//     }
// }
</script>
