import {io} from 'socket.io-client';
import * as api from './api';

const socketIoOptions = {};
const socketUrl = api.socketUrl;
console.log('socket config', {socketUrl, socketIoOptions});

let socketInstance;

const wrapper = {
  on() {
    return socketInstance.on(...arguments);
  },
  emit() {
    return socketInstance.emit(...arguments);
  },
  removeAllListeners() {
    return socketInstance.removeAllListeners(...arguments);
  }
};

export function setUserToken(token) {
  // console.log('setting user token',token);
  // socketIoOptions.auth = { token };
  socketIoOptions.extraHeaders = {
    Authorization: `Bearer ${token}`
  }
  if (socketInstance) {
    socketInstance.disconnect();
  }
  socketInstance = io(socketUrl, socketIoOptions);
  console.log('setting user token complete');

  socketInstance.on('disconnect', (reason) => console.log('disconnected', reason));
}

// export function createWebSocketPlugin(socket) {
//   return store => {
//     store.$socket = socket
//     socket.on('message', payload => {
//       console.log('received message', payload);
//       return store.dispatch('receiveMessage', payload);
//     })
//   }
// }
//
// // this is used by vuex
// export const webSocketPlugin = createWebSocketPlugin(wrapper);


export default wrapper;
