import moment from 'moment';

export function deepCopy(o) {
  if (o) {
    return JSON.parse(JSON.stringify(o));
  } else {
    return o;
  }
}

export function formatDuration(ms, fractionalMinutes = false) {
  if (ms && !isNaN(ms)) {
    if (fractionalMinutes) {
      return ((ms / 1000) / 60).toFixed(2);
    } else {
      const m = moment.utc(ms || 0);
      if (m.hours() > 0) {
        return m.format('HH:mm:ss');
      } else {
        return m.format('mm:ss');
      }
      // const minutes = `${Math.floor((ms / 1000) / 60)}`.padStart(2,'0');
      // const seconds = `${Math.round((((ms / 1000) / 60) % 1) * 60)}`.padStart(2,'0');
      // return `${minutes}:${seconds}`;
    }
  } else {
    return '';
  }
}

export function dateFormatted(date, {append, prepend, formatString} = {}) {
  formatString =
    (prepend || '') +
    (formatString || 'dddd MMM DD') +
    (append || '');
  if (date) {
    return moment(date).format(formatString);
  } else {
    return '';
  }
}

export function getDatesInRange(from, to, limit = 31) {
  if (!from || !to) {
    return [];
  }
  from = moment(from);
  to = moment(to);

  const dates = [];
  const diff = moment.duration(to.diff(from));

  if (diff.asDays() >= 0 && diff.asDays() <= limit) {
    // console.log('adding days ', {from, to})
    while (from.isSameOrBefore(to)) {
      const dateString = getDateString(from);
      // console.log('added date', dateString);
      dates.push(dateString);
      from.add(1, 'day');
    }
  } else {
    window.alert(diff.asDays() + ' days is above the limit of ' + limit);
  }
  // console.log('getDatesInRange', dates);
  return dates;
}


function getStartOfWeek(date) {
  return getDateString(moment(date).startOf('week'));
}

export function getDateString(date) {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  } else {
    return '';
  }
}

export function formatWeightWithUnits(amount) {
  const units = ['g', 'kg'];
  const decimalPlaces = [1, 2];
  return formatWeight(amount, units, decimalPlaces);
}


export function formatWeight(value, units = [], decimalPlaces = []) {
  units = [...units];
  decimalPlaces = [...decimalPlaces];

  if (value === undefined || Number.isNaN(value)) {
    // eslint-disable-next-line no-debugger
    // debugger;
    console.log('warning formatWeight called with ' + value);
    // throw new Error('fail ' + value);
    return 0;
  }
  value = Number.parseFloat(value);
  // return value.toFixed(2);

  if (value === 0) {
    const unit = units[0] || '';
    return `0${unit}`;
  }

  let selectedUnit = units.shift() || '';
  let fractionDigits = decimalPlaces.shift();
  if (fractionDigits === undefined) {
    fractionDigits = 2;
  }
  while (units.length > 0 && value >= 999) {
    selectedUnit = units.shift();
    if (decimalPlaces.length > 0) {
      fractionDigits = decimalPlaces.shift();
    }
    value = value / 1000;
  }

  const numberPart = value.toFixed(fractionDigits)
    // remove trailing zeros
    .replace(/\.0+$/, '');

  if (numberPart === '-0') {
    return `0${selectedUnit}`;
  } else {
    return `${numberPart}${selectedUnit}`;
  }
}

function convertIngredient(i) {
  if (!i.fooddescriptionsimple) {
    i.fooddescriptionsimple = i.food_name ? i.food_name.fooddescriptionsimple : '';
  }

  // ugh, so much crap by different naming
  const portionBasis = i.portion_basis || i.measureid;
  const hasPortionBasisButNotStandardMeasureAndNoConversion = portionBasis && portionBasis != 1455 && !i.conversionfactorvalue;
  if (hasPortionBasisButNotStandardMeasureAndNoConversion) {
    console.log(`convertIngredient ${i.fooddescriptionsimple} cf=${!i.conversionfactorvalue}`, i);
    console.warn('measure is not in 100g', portionBasis);
    const mealOrComponent = i.mealid ? `M${i.mealid}` : `C${i.component_id}`;
    alert('Ingredient has non standard measure!\n\n'
      + `${i.fooddescriptionsimple} measure type ${portionBasis} in ${mealOrComponent}`);
    throw new Error('cannot convert amount');
  }
  const shoppingmultiplier = i.shoppingmultiplier || (i.food_name && i.food_name.shoppingmultiplier);
  if (shoppingmultiplier) {
    // i.percentYield = ((1 / m) * 100).toFixed();
    i.percentChange = ((shoppingmultiplier - 1) * 100).toFixed();
  }
  const portionMultiplier = i.portion_multiplier || i.portionmultiplier;  // I HATE THIS SO MUCH
  i.cookedAmount = (i.conversionfactorvalue || 1) * portionMultiplier * 100;
  i.rawAmount = i.percentChange
    ? i.cookedAmount + (i.cookedAmount * (i.percentChange / 100))
    : i.cookedAmount;

  i.buyAmount = (i.trim_multiplier || 1) * i.rawAmount;
  if (i.trim_multiplier) {
    i.percentTrim = ((i.trim_multiplier - 1) * 100).toFixed();
  }

  i.cookedAmounts = {
    medium: i.cookedAmount,
  };

  i.rawAmounts = {
    medium: i.rawAmount
  }

  i.buyAmounts = {
    medium: i.buyAmount
  }
  let c = i.component;
  if (!c) {
    console.error('no component found', {component_id: i.component_id, i});
    c = {monosized: true};
  }

  if (c.monosized) {
    i.rawAmounts.small = i.rawAmount;
    i.cookedAmounts.small = i.cookedAmount;
    i.rawAmounts.large = i.rawAmount;
    i.cookedAmounts.large = i.cookedAmount;
    i.buyAmounts.small = i.buyAmount;
    i.buyAmounts.large = i.buyAmount;
  } else {
    i.rawAmounts.small = c.small_ratio * i.rawAmount;
    i.cookedAmounts.small = c.small_ratio * i.cookedAmount;
    i.rawAmounts.large = c.large_ratio * i.rawAmount;
    i.cookedAmounts.large = c.large_ratio * i.cookedAmount;
    i.buyAmounts.small = i.buyAmount * c.small_ratio;
    i.buyAmounts.large = i.buyAmount * c.large_ratio;
  }

  if (!i.fooddescription) {
    i.fooddescription = '';
  }

  if (hasPortionBasisButNotStandardMeasureAndNoConversion) {
    console.log('convertedIngredient', i);
  }

  return i;
}

function convertIngredientBack(i) {
  i = Object.assign({}, i);
  const p = i.percentChange;
  // if value was set, or value was previously set, update it
  if (p || i.shoppingmultiplier) {
    i.shoppingmultiplier = (p / 100) + 1;
  }

  // i.cookedAmount = (i.conversionfactorvalue || 1) * i.portion_multiplier * 100;
  i.portion_multiplier = (i.cookedAmount / 100) / (i.conversionfactorvalue || 1);
  return i;
}

function getBestBefore(date) {
  // March 17, 2020
  return moment(date).add(2, 'days').format('MMM D, YYYY');
}

function getFullDay(date) {
  return moment(date).format('dddd');
}

export {
  getStartOfWeek,
  convertIngredient,
  convertIngredientBack,
  getBestBefore,
  getFullDay
}

export function ensureNumber(value) {
  return Number.parseFloat(value);
}

export function ensureValuesNumbers(object) {
  return Object.fromEntries(
    Object.entries(object)
      .map(([k, v]) => {
        if (typeof v === 'number') {
          return [k, v];
        }
        if (typeof v === 'string') {
          return [k, Number(v)];
        }
        if (typeof v === 'object') {
          return [k, ensureValuesNumbers(v)];
        }
        if (v === undefined || v === null) {
          return [k, 0];
        }
        alert(`Could not convert ${typeof v} to a numbers`);
        console.error('failure converting to number', {k, v, object});
        throw new Error(`Could not convert ${typeof v} to a numbers`);
      })
  )
}

export function ensureInt(value) {
  return Number.parseInt(value);
}

export function assignIngredientAmounts(newIngredient, oldIngredient) {
  newIngredient.component_id = oldIngredient.component_id;
  newIngredient.component = oldIngredient.component;
  newIngredient.cookedAmount = oldIngredient.cookedAmount;
  newIngredient.percentChange = oldIngredient.percentChange;
  // we always save in 1455 - measure of 100g units, as this is defined for all ingredients
  newIngredient.portion_basis = 1455; //oldIngredient.portion_basis;
  newIngredient.portion_multiplier = oldIngredient.cookedAmount / 100;
  // console.log('assignIngredientAmounts', {newIngredient,oldIngredient});
}

export function removeCC(id) {
  try {
    const idCleaned = parseInt(`${id}`.replace(/CC$/, ''));
    if (isNaN(idCleaned)) {
      console.warn('unabled to parse id', id);
      throw new Error('not able to parse id');
    }
    return idCleaned;
  } catch (e) {
    return id;
  }
}

export function extractFoodId(item) {
  try {
    return item.name.split(':::')[0].split(' ').reverse()[0].replace('[', '').replace(']', '');
  } catch (e) {
    console.warn('failed to extract id', e);
    return '?';
  }
}

export function promisePause(timeout) {
  return new Promise(resolve => setTimeout(() => resolve(true), timeout));
}


export function csvExport(arrData, filename) {
  if (!arrData || arrData.length === 0) {
    alert('no data to export');
    return;
  }
  let csvContent = '';
  const delim = ',';

  function stringify(item) {
    return Object.entries(item || {}).map(([k, v]) => `${k}:${v}`).join(' ');
  }

  csvContent += [
    Object.keys(arrData[0]).map(item => `"${item}"`).join(delim),
    ...arrData.map(item => Object.values(item)
      .map(item => {
        if (Array.isArray(item)) {
          item = item.join(', ');
        } else if (typeof item === 'object') {
          item = stringify(item);
        }
        return `"${item}"`;
      })
      .join(delim))
  ]
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '');

  const link = document.createElement('a');
  const csvBlob = new Blob([csvContent], {type: 'text/csv'});
  link.href = URL.createObjectURL(csvBlob);
  link.setAttribute('download', filename);
  link.click();
}

export function csvAsTable(items, headers, search) {
  search = search && new RegExp(search, 'i');
  const rows = [];
  // allow gets from props like thing.subthing
  const get = (from, ...selectors) =>
    [...selectors].map(s =>
      s
        // .replace(/\[([^\[\]]*)\]/g, '.$1.')
        .split('.')
        .filter(t => t !== '')
        .reduce((prev, cur) => prev && prev[cur], from)
    );
  for (const item of items) {
    if (search) {
      if (!search.test(headers.map(h => get(item, h.value)).join(' ')))
        continue;
    }
    rows.push(Object.fromEntries(headers.map(h => {
      let itemElement = get(item, h.value);
      if (itemElement.length && itemElement.length === 1) {
        // if element is array of one thing, just make it thing
        itemElement = itemElement[0];
      }
      return [h.text, itemElement || ''];
    })))
  }
  console.log('rows', rows);
  return rows;
}

const currencyFormat = new Intl.NumberFormat('en-CA',
  {style: 'currency', currency: 'CAD'});

export function formatCurrency(value) {
  return currencyFormat.format(value / 100);
}

// export function formatCurrency(value, {trimZeroCents} = {}) {
// value = value || 0;
// const str = `$${(parseInt(value) / 100).toFixed(2)}`;
// if (trimZeroCents) {
//   return str.replace(/\.00$/, '');
// }
// return str;
// }


export function shortenBin(binString) {
  function abbr(name) {
    return name.substring(0, 3);
  }

  function shorten(b1, b2) {
    const [name1, number1] = b1.split('-');
    const [name2, number2] = b2.split('-');
    if (name1 === name2) {
      return `${(abbr(name1))}-${number1}..${number2}`.toUpperCase();
    } else {
      return `${(abbr(name1))}-${number1}..${abbr(name2)}-${number2}`.toUpperCase();
    }
  }

  const bins = (binString || '').split(',');
  if (bins.length > 1) {
    return shorten(bins[0], bins[bins.length - 1]);
  } else {
    return bins.join(', ');
  }
}

export function capitalise(str) {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}


/**
 * gets the production day groupings
 */
function getDayGroups(date) {
  if (moment(date).isSameOrAfter('2023-12-03', 'day')) {
    return [
      [1, 2, 3], [4, 5, 6]
    ];
  } else {
    return [
      [1, 2], [3, 4], [5, 6]
    ];
  }
}

export function getNextProductionDays(date) {
  let day = moment(date).day();
  if (day === 0) {
    //shift sunday to monday
    day = 1;
  }
  const dayGroups = getDayGroups(date);
  const group = dayGroups.find(group => !group.includes(day));
  if (group) {
    if (group[1] < 3) {
      date = moment(date).add(1, 'week');
    }
    return group.map(d => moment(date).day(d).format(moment.HTML5_FMT.DATE));
  } else {
    return [moment(date).format(moment.HTML5_FMT.DATE)];
  }
}

export function getProductionDays(date) {
  const day = moment(date).day();
  const dayGroups = getDayGroups(date);
  const group = dayGroups.find(group => group.includes(day));
  if (group) {
    return group.map(d => moment(date).day(d).format(moment.HTML5_FMT.DATE));
  } else {
    return [moment(date).format(moment.HTML5_FMT.DATE)];
  }
}

export function getDeliveryInstruction(customer, date) {
  return getAddress(customer, date).delivery_instruction || customer.delivery_instruction;
}

export function getAddress(customer, date) {
  date = moment(date);
  const day = date.format('ddd').toLowerCase();
  const is2DayDelivery = date.isSameOrAfter('2023-12-03', 'day');
  let addressMap;
  if (customer && customer.useAddress) {
    addressMap = {};
    if (is2DayDelivery) {
      if (customer.useAddress.mon_tue_wed) {
        addressMap.mon = customer.useAddress.mon_tue_wed;
        addressMap.tue = customer.useAddress.mon_tue_wed;
        addressMap.wed = customer.useAddress.mon_tue_wed;
      }
      if (customer.useAddress.thu_fri_sat) {
        addressMap.thu = customer.useAddress.thu_fri_sat;
        addressMap.fri = customer.useAddress.thu_fri_sat;
        addressMap.sat = customer.useAddress.thu_fri_sat;
      }
    } else {
      if (customer.useAddress.mon_tue) {
        addressMap.mon = customer.useAddress.mon_tue;
        addressMap.tue = customer.useAddress.mon_tue;
      }
      if (customer.useAddress.wed_thu) {
        addressMap.wed = customer.useAddress.wed_thu;
        addressMap.thu = customer.useAddress.wed_thu;
      }
      if (customer.useAddress.fri_sat) {
        addressMap.fri = customer.useAddress.fri_sat;
        addressMap.sat = customer.useAddress.fri_sat;
      }
    }
  }
  if (addressMap && addressMap[day]) {
    return addressMap[day];
  } else {
    return customer.address;
  }
}

export function formatAddress(a, {short = true, excludeUnit = false} = {}) {
  function formatPostcode(s) {
    return (s || '').replaceAll(' ', '').toUpperCase();
  }

  const city = short
    ? (a.city || '').replace(/\(.+\)/g, '').trim()  // regex to turn "Squamish (HOURS REMOVE THIS)" into "Squamish"
    : false;
  return `${[
    ((!excludeUnit && a.unit) ? `${a.unit.trim()}-` : '') + a.line1,
    a.line2,
    city,
    formatPostcode(a.postal_code),
    // (a.location === 'office' ? 'office' : false)
  ]
    .filter(a => !!a)
    .map(s => s.trim())
    .join(', ')
  }`;
}

export const filterSelectedIngredients = (selectedFoodIds, allergyDetected) => {
  if (allergyDetected) {
    allergyDetected = JSON.parse(JSON.stringify(allergyDetected));
    Object.keys(allergyDetected)
      .forEach(allergy => {
        const foodIds = Object.keys(allergyDetected[allergy]);
        foodIds.forEach(foodId => {
          if (!selectedFoodIds.includes(parseInt(foodId))) {
            delete allergyDetected[allergy][foodId];
          }
        });
        if (Object.keys(allergyDetected[allergy]).length === 0) {
          delete allergyDetected[allergy];
        }
      });
  }
  return allergyDetected;
}

export function calculateSummary(orders) {
  return orders
    .reduce((s, order) => {
      s[order.meal_size] = s[order.meal_size] + order.quantity;
      s.total = s.total + order.quantity;
      return s;
    }, {
      small: 0,
      medium: 0,
      large: 0,
      total: 0
    });
}

export function filterByAllergyDetected(orders, selectedFoodIds, isDetected) {
  // console.log('filterByAllergyDetected', orders, selectedFoodIds);
  isDetected = !!isDetected;
  return orders.filter(o => {
    if (o.allergyDetected) {
      if (!selectedFoodIds || selectedFoodIds.length === 0) {
        return isDetected;
      }
      const allergyDetected = {...o.allergyDetected};
      // TODO: damn these non-allergy
      delete allergyDetected['extra protein'];
      delete allergyDetected['extra large'];
      if (Object.values(allergyDetected)
        .flatMap(a => Object.keys(a))
        .some(foodid => selectedFoodIds.includes(foodid))) {
        return isDetected;
      }
    }
    return !isDetected;
  })
}

export const normaliseIngredient =
  (meal) =>
    (i) => {
      // FIXME: this makes me mad/sad.  consistent naming failure
      return {
        mealid: meal ? meal.id : null,
        component_id: i.component_id,
        foodid: i.food_id || i.foodid,
        portion_basis: i.measure_id || i.measureid,
        portion_multiplier: i.portionmultiplier || (i.amount / 100),
        fooddescriptionsimple: i.food_name && i.food_name.fooddescriptionsimple,
        shoppingmultiplier: i.food_name && i.food_name.shoppingmultiplier,
        price: i.food_name && i.food_name.price
      };
    }

export function formatDate(date) {
  // console.log('foramt date',[ date, moment(date).utc().format(moment.HTML5_FMT.DATE)]);
  return moment(date).utc().format(moment.HTML5_FMT.DATE);
}


export function getOrderDeadline() {

  const now = moment();
  let deadline;
  try {
    deadline = moment('Thursday 12:00', 'dddd hh:mm');
  } catch (e) {
    console.error('failed to set config order deadline, using default midnight');
    deadline = now.clone().day(5).startOf('day')
  }

  if (now > deadline) {
    return deadline.add(1, 'week').format(moment.HTML5_FMT.DATETIME_LOCAL);
  } else {
    return deadline.format(moment.HTML5_FMT.DATETIME_LOCAL);
  }
}


export function getNextOrderingDate() {
  const deadline = moment(getOrderDeadline());
  return deadline.startOf('week').add(1, 'week').format(moment.HTML5_FMT.DATE);
}


const specialRegexChars = /[/\-\\^$*+?.()|[\]{}]/g;

export function escapeRegex(string) {
  return string.replace(specialRegexChars, '\\$&');
}

export function getPlatingTimeEstimate(mealCount) {
  /**
   * logic
   2 min setup time
   1-20 meals = 1 minute/meal
   21-60 meals = .5 minutes/meal
   61+ = .25 minutes/meal
   */
  let rate;
  switch (true) {
    case (mealCount <= 20) :
      rate = 1;
      break;
    case (mealCount <= 60) :
      rate = 0.5;
      break;
    default: // over 60
      rate = .25;
  }
  const minutes = 2 + (mealCount * rate);
  // console.log('plating estimate', {mealCount, rate, minutes});
  return minutes * 60 * 1000;
}

export function getComponentSizeRatio(component, size) {
  if (size === 'medium' || component.monosized) {
    return 1;
  } else {
    const defaultRatio = {
      small: 0.75,
      large: 1.25
    }
    return component[`${size}_ratio`] || defaultRatio[size];
  }
}

export function getRawComponentAmountForMeal(component, meal, size) {
  const ingredientsInComponent = meal.meal_food
    .filter(mf => mf.component_id === component.id)
    .filter(mf => !mf.ignore_prep_weight);
  // console.log('ingredientsInComponent', ingredientsInComponent);
  const amounts = ingredientsInComponent
    .map(mf => mf.rawAmount);
  // console.log('amounts', amounts);
  const componentAmount = amounts
    .reduce((i, sum) => i + sum, 0);
  const ratio = getComponentSizeRatio(component, size);
  // console.log('component amount', component.name, componentAmount, ratio)
  return componentAmount * ratio;
}

export function getComponentAmountForMeal(component, meal, size) {
  const ingredientsInComponent = meal.meal_food
    .filter(mf => mf.component_id === component.id)
    .filter(mf => !mf.ignore_prep_weight);
  // console.log('ingredientsInComponent', ingredientsInComponent);
  const amounts = ingredientsInComponent
    .map(mf => mf.cookedAmount);
  // console.log('amounts', amounts);
  const componentAmount = amounts
    .reduce((i, sum) => i + sum, 0);
  const ratio = getComponentSizeRatio(component, size);
  // console.log('component amount', component.name, componentAmount, ratio)
  return componentAmount * ratio;
}

export function sortBySize(countMap) {
  const result = {};
  for (const size of new Set(['small', 'medium', 'large', ...Object.keys(countMap)])) {
    if (countMap[size])
      result[size] = countMap[size];
  }
  return result;
}
